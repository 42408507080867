.root {
    min-height: 100vh;
}

.navbar-light .navbar-nav .nav-link.active {
    color: rgba(186, 186, 186, 1) !important;
    text-underline: #adb8c0;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1);
}

.padding {
    height: 55px;
}

.card-deck {
    padding-bottom: 15px;
}

.close-elem-ico {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 101;
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: block;
}

.box {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.box img {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
    -ms-transform: translate(-50%, -50%);
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    -o-object-fit: cover;
    object-fit: cover;
}

.my-modal {
    position: fixed;
    padding: 0 15px;
    top: 70px;
    left: 0;
    width: 100%;
    height: 30%;
    z-index: 2000;
    overflow: hidden;
    background: white;
    transition: 0.8s; /*Скорость перехода состояния элемента*/
    animation: height, top 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    animation-delay: 0s; /* Задержка перед началом */
}

.modal-order {
    position: fixed;
    padding: 0 15px;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3000;
    text-align: center;
    overflow: hidden;
    background: rgba(199, 199, 199, 0.6);
}

.modal-order-form {
    width: 80%;
    height: 50%;
    background: #2a2a2a;
    border: 1px solid #f3f37d;
    top: 5%;
    position: relative;
    left: 10%;
}

.shot-menu .my-modal {
    height: 0;
}
.long-menu .my-modal {
    height: 33%;
    background-color: #fab30d;
}

.modal-project-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.81);
}

.modal-open {
    overflow: hidden;
}

.hint {
    font-size: 10px;
    font-style: italic;
    color: darkgrey;
    margin-top: -8px;
    padding-bottom: 10px;
}

.sticky{
    position: sticky;
    top: 0;
}

.fixed-top {
    width: 100vw;
    /*transition: 2s; !*Скорость перехода состояния элемента*!*/
    /*animation: show 1s 1; !* Указываем название анимации, её время и количество повторов*!*/
    /*animation-fill-mode: forwards; !* Чтобы элемент оставался в конечном состоянии анимации *!*/
    /*animation-delay: 0s; !* Задержка перед началом *!*/
}

@keyframes show {
    0% {
        margin-top: -50px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes hinge {
    100% {
        margin-top: 0;
    }
    0% {
        margin-top: -50px;
    }

}

.dlt-margin {
    margin-left: 290px;
    margin-top: -67px;
    padding-bottom: 35px;
}

.pointer {
    cursor: pointer;
}

.menu {
    background: #2a2a2a;
    letter-spacing: .2em;
    color: rgb(252, 252, 252);
    line-height: 14px;
    font-size: 16px;
    max-height: 75px;
}

.description {
    background: white;
    letter-spacing: .1em;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
    text-indent: 1.5em; /* Отступ первой строки */
    text-align: justify; /* Выравнивание по ширине */
    line-height: 16px;
}

.menu a {
    color: rgb(252, 249, 249);
}

.menu a:hover, .menu .active {
    color: rgb(245, 162, 9);
    text-decoration: underline rgb(245, 162, 9);

}

.my-modal .menu {
    transition: 1s; /*Скорость перехода состояния элемента*/
}

.invisible-block {
    visibility: hidden;
}

.visible-block:hover ~ .invisible-block {
    visibility: visible;
}

.invisible-block:hover {
    visibility: visible;
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.demo-editor {
    height: 225px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.prices p {
    margin-bottom: 0 !important;
}

.ordering .container {
    max-width: 590px !important;
}

.open-label {
    padding-right: 5px;
    font-style: italic;
}

.open-label:hover {
    color: #f3f37d;
    padding-right: 5px;
    text-decoration: #f3f37d underline;
    cursor: pointer;

}

span.arr-btn:hover {
    border-bottom: 5px solid #f3f37d;
}

span.arr-top:hover {
    border-top: 5px solid #f3f37d;
}

span.arr-btn {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 5px solid #ffffff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

span.arr-top {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid #ffffff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.fixed-menu {
    display: block;
    background-color: #454d55;
    background: #454d55;
}

.fixed-menu .menu-top {
    display: block;
    line-height: 60px;
}

.only-phone {
    display: none;
}

.mobile-menu{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 20px;
}

.menu-padding{
    padding-top: 70px;
}

/*Для большого экрана*/
@media only screen and (min-width: 951px) {

    .mobile-menu{
        display: none;
    }

    .mobile-price {
        display: block;
    }

    .background-fon {
        background: url("https://interiormydreams.ru/static/fon/fon.jfif");
    }

    .menu-top {
        display: block;
    }

    .size-logo {

    }

    .size-logo img {
       height: 75px;
        padding-left: 50px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .cell-div-big {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 77%;
    }

    .menu-left {
        display: none;
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 31vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 31vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }

    .no-desctop {
        display: none !important;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .news img {
        width: 100vw !important;
        height: auto !important;
    }

    .mobile-menu{
        display: none;
    }

    .services_top {
        border: none !important;
        padding: 0  !important;
    }

    .mobile-price {
        display: none;
    }

    .services_row {
        display: block !important;
    }

    .services_col {
        width: 100% !important;
    }

    .only-desctop {
        display: none !important    ;
    }

    .menu-top {
        display: block;
    }

    .size-logo img {
        width: 65%;
    }

    .no-tablet {
        display: none !important;
    }

    .sun-editor-editable .no-tablet div {
        display: none !important;
    }

    .cell-div-big {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 80%;
    }

    .menu-left {
        display: none;
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 62vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 62vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {

    .news img {
        width: 100% !important;
        height: auto !important;
    }

    .services_top {
        border: none !important;
        padding: 0  !important;
    }

    .mobile-price {
        display: none;
    }

    .services_row {
        display: block !important;
    }

    .services_col {
        width: 100% !important;
    }

    .only-desctop {
        display: none !important;
    }
    .menu-top {
        display: none;
    }

    .only-phone {
        display: block;
    }

    .size-logo img {
        height: 70px;
        padding: 10px;
    }

    .no-phone {
        display: none !important;
    }

    .sun-editor-editable .no-phone div {
        display: none !important;
    }

    .cell-div-big {
        display: none !important;
    }

    .menu-left {
        display: block;
    }

    .project-img-with-description-all {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 93vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 93vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }
}

/*слайдер просмотра изображеий*/

.project-image-modal {
    text-align: center;
    background: white;
    padding: 3px 0;
    width: auto;
    will-change: transform;
}

.project-image-modal-h {
    height: 100vh;
}

.project-image-modal-w {
    height: auto;
    width: 100vw;
    margin: auto;
}

.box2 img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    will-change: transform;
}

.box3 img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    will-change: transform;
    display: inline-block;
}

.box3 {
    height: 100%;
    background: #000000;
}

/*end*/

@media (min-width: 1200px) {
    .order-field {
        width: 550px;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 550px;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .order-field {
        width: 490px;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 490px;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

@media (max-width: 991px) {

    .order-field {
        width: 95vw;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 95vw;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

.table-container {
    width: 100%;
    margin: 10px auto;
    /* display: table; */
    border-collapse: collapse;
}

.form-row {
    /* display: table-row; */
    display: table;
    width: 100%;
    float: left;
    border-collapse: collapse;
}

.cell {
    display: table-cell;
    padding: 10px 20px;
    vertical-align: top;
    border-collapse: collapse;
    text-align: justify;
    text-indent: 1.5em;
}

.cell-info {
    display: table-cell;
    padding: 10px 20px;
    vertical-align: top;
    border-collapse: collapse;
    text-align: justify;
    width: 35%;

}

.color-white {
    color: white !important;
}

.size-18 {
    font-size: 2vmin;
    text-align: justify;
}

.border-b {
    border-bottom: 1px solid #4b4b4b;
}

.portfolio-link {

}

@font-face {
    font-family: 'Playfair';
    src: url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.eot'); /* IE 9 Compatibility Mode */
    src: url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.svg#playfair_display_regular') format('svg'); /* Chrome < 4, Legacy iOS */
}

.table-div {
    display: table !important;
}

.row-div {
    display: table-row !important;
}

.cell-div-10 {
    display: table-cell;
    vertical-align: middle;
    text-align: end;
}

.padding-10 {
    margin-bottom: 10px !important;;
}

.cell-div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.cell-div-right {
    display: table-cell !important;
    vertical-align: middle;
    text-align: right;
    width: 17%;
    padding: 1px 1px 1px 0px;
}

.cell-div-100 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.h-15 {
    line-height: 20px;
    font-size: 1.8vmin !important;
}

.border-bt {
    border-bottom: 1px solid white;
}

.vertical-top {
    vertical-align: top;
}

.vertical-bottom {
    vertical-align: bottom;
    display: table-cell;
    border-bottom: 1px solid white;
}

.nav-link {
    display: inline-block;
}


.services_row {
    display: flex;
    margin: 0 -10px;
    margin-bottom: 40px;
}

.services_col {
    /*width: 25%;*/
    width: 32%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.services_item {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.services_top {
    border: 1px solid #dddede;
    padding: 25px 10px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.services_heading {
    font-size: 20px;
    text-align: center;
    height: 50px;
    font-weight: 700;
}

.services_bottom {
    font-size: 18px;
    color: #000000;
    background: rgb(245, 162, 9);
    text-align: center;
    padding: 9px 0;
}

.services_bottom span {
    font-size: 26px;
    font-weight: 500;
}

.services_list ul {
    list-style: none;
    list-style-type: none !important;
    padding-inline-start: 15px !important;
}

.services_list li {
    padding: 3px 0;
    border-bottom: 1px solid #dddede;
    padding-left: 25px;
    background-image: url("https://interiormydreams.ru/static/media/li.png");
    background-position: left 0px center;
    background-repeat: no-repeat;
}

#priceContainer {
    max-width: 1700px
}

.open-btn {
    transform: rotate(-90deg);
    width: 5%
}
.close-btn {
    width: 5%
}

.tag-list {
    list-style: none;
    height: calc(100vh * 0.55);
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap
}
.tag-list li {
    page-break-inside: avoid;
    break-inside: avoid;
}

.tag-block{
    height: calc(100vh * 0.55);
}

.modal-tag{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
    background: white;
}

#news_container .se-container {
    width: 98.2% !important;
}

#news_container .sun-editor {
    border: 0px solid #dadada;
}

.text-center{
    text-align: center;
}

.text-white{
    color: white;
}

.clear{clear:both;}


.anim-from-left-to-right2 {
    animation-name: fromLTR2;
    animation-duration: 1s;
}




.anim-from-right-to-left2  {
    animation-name: fromRTL2;
    animation-duration: 1s;

}


div, span, a, h1, h2, h3, h4, input, textarea, button {
    font-family: Playfair !important;
}

.arrow-next{
    color: white;
    fill: #fff;
    animation: shiftByY 1s alternate  ease-in-out infinite;
}
