

.input {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #eb9c34;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 10px 0;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
}

.privacy-policy a {
    color: white;

}
.privacy-policy a:hover {
    color: rgb(245, 162, 9);
}

.personal-data-style a {
    color: white;
    text-decoration: white underline;
}

.personal-data-style a:hover {
    color: rgb(245, 162, 9);
    text-decoration: none;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .footer {
        background-color: #2a2a2a;
        text-align: center;
        align-content: center;
        color: white;
        padding-top: 20px;
        min-height: 91vh;
    }

    .f-inline {
        display: inline-block;
        width: 50%;
    }

    .f-contact-info {
        font-size: 30px;
        text-align: left;
        padding-bottom: 15px;
        padding-left: 300px;
    }

    .privacy-policy {
        font-size: 18px;
        padding-top: 125px;
    }

    .f-form {
        width: 70%;
    }

    .footer-info {
        position: relative;
        font-size: 20px;
        width: 40%;
        left: 30%;
        padding-top: 20px;
        padding-bottom: 40px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .footer {
        background-color: #2a2a2a;
        text-align: center;
        align-content: center;
        color: white;
        padding-top: 50px;
        min-height: calc(105vh);
    }


    .f-contact-info {
        font-size: 25px;
        text-align: center;
        padding-bottom: 10px;
    }

    .privacy-policy {
        font-size: 18px;
        padding-top: 0px;
    }

    .f-form {
        width: 100%;
        padding: 0 10px 0 10px;
    }

    .footer-info {
        font-size: 15px;
        padding: 15px 10px 15px 10px;
    }

    .input {
        margin: 5px 0;
        height: 45px;
    }
}



.input-textarea {
    height: 120px;
}



.f-top{
    vertical-align: top;
}

.ft-btn{
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 3s ease-in-out alternate;
    -webkit-animation: leaves 3s ease-in-out alternate;
}

/*.ft-btn{*/
/*    top: calc(25vh);*/
/*}*/