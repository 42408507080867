html {
    scroll-behavior: smooth;
}

.element-animation {
    margin: 2rem 0;
    font-size: 3rem;
    opacity: 0;
}

.element-animation.element-show {
    animation-name: bounceInUp;
    animation-duration: 1s;
    opacity: 1;
}

.test-img-blok {
    width: 100%;
    height: calc(100vh);
    border-radius: 31px;
    text-align:center;
    vertical-align: middle;
}

.slider-image {
    width: 100%;
    height:100%;
    object-fit:cover;
}

.first {
    animation-name: bounceInUp1;
    animation-duration: 1.5s;
    /*box-shadow: 10px 10px 10px rgba(158, 162, 156, 0.85);*/
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.second {
    animation-name: bounceInUp2;
    animation-duration: 2s;
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.third{
    animation-name: bounceInUp3;
    animation-duration: 2.5s;
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.hide {
    opacity: 0;
}

.show {
    opacity: 1;
}

.logo-position {
    position: absolute;
    top: 22vh;
    left: 35%;
    z-index: 100;
    border-radius: 50px;
}

.logo-position img {
    background: radial-gradient(black, rgba(0, 0, 0, 0.49) 50%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0));
    border-radius: 50%;
}

.anhor-link-a:hover .anhor-link{
    width: 190px;
}
.anhor-link-a:hover .block-rt {
    right: 190px;
}

.anhor-link {
    position: absolute;
    right: 0;
    padding: 3px;
    display: block;
    width: 170px;
    margin-left: auto;
    z-index: 1000;
    color: white;
    cursor: pointer;
}

.block-rt {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    right: 170px;
    border: 15px solid transparent; /* Прозрачные границы */
     /* Добавляем треугольник */
    z-index: 1000;
}
.block-rt::after {
    border: 20px solid transparent; border-right: 20px solid green;
}


/*Для большого экрана*/
@media only screen and (min-width: 951px) {


    .test-blok {
        width: 23%;
        height: 20vw;
        border-radius: 31px;
        padding: 25px;
        margin: 10px;
    }
    .test-blok2 {
        width: 10%;
        height: 10vw;

        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .test-blok {
        width: 45%;
        height: 414px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
    .test-blok2 {
        width: 30%;
        height: 214px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {


    .test-blok {
        width: 90%;
        height: 414px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
    .test-blok2 {
        width: 90%;
        height: 214px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}


@keyframes bounceInUp {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp1 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp2 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 6000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp3 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 9000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}