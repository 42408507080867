.calculator-top {
    margin-top: 0px;
    text-align: center;
    vertical-align: top;
    padding: 30px;
}

.calculator-title {
    position: relative;
    animation: leaves 2s ease-in-out alternate;
    -webkit-animation: leaves 2s ease-in-out alternate;
    padding-top: 10px;
    text-align: center;
}

.calculate-tariff-result {
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #2a2a2a;
    margin-bottom: 150px;
    overflow: hidden;
    box-shadow: 15px 15px 12px #00000094
}
.calculate-tariff-result div div .tariff-description {
    padding-left: 35px;
}


.calculator-price {
    color: #fab30d;
    font-size: 40px;
    text-align: center;
    width: 100%;
}

.row {
    display: table-row;
}

.modal-br {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 500;
}


.close-modal:hover svg {
    animation-name: scaleBtn;
    animation-duration: 0.2s;
    transform: scale(1.2);
}

@keyframes scaleBtn {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

.calculator-modal {
    position: fixed;
    top: 15%;
    left: 25%;
    width: 50%;
    border-radius: 10px;
    border: 1px solid #fab30d;
    background-color: #2a2a2a;
    z-index: 501;
    box-shadow: 15px 15px 12px #00000094;
    padding: 20px;
    color: white;
    font-size: 20px;
    animation-name: ascleModal;
    animation-duration: 0.4s;
}

@keyframes ascleModal {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.close-calculator {
    position: relative;
    top: -75px;
    left: 99%;
    cursor: pointer;
}

.black {
    color: white;

}

.calculator-next-step {
    width: 20%;
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    padding-right: 10px;
}

.calculator-cell > .mp-btn {
    top: 0
}

.calculator-confirm-btn > .mp-btn {
    top: 0;
    margin-top: 20px;
}


.calculator-cell {
    vertical-align: middle;
    display: table-cell;
}

.calculator-cell a {
    color: white;
}

.calculator-cell a:hover {
    color: #fab30d;
    text-decoration: none;
}

.calculator-prev {
    height: 15vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-current {
    height: 30vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-current-big {
    height: 40vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-next {
    height: 15vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

/*чекбокс*/
.clc-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;

    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
    margin: 0 auto;
}

/* Hide the browser's default checkbox */
.clc-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    /*background-color: white;*/
    border: 1px solid black;
    border-radius: 7px;
}

.white-border > .checkmark {
    border: 1px solid white;

}

.orange-border > .checkmark {
    border: 1px solid #fab30d;
}

/* On mouse-over, add a grey background color */
.clc-checkbox:hover input ~ .checkmark {
    /*background-color: #ccc;*/
}

/* When the checkbox is checked, add a blue background */
.clc-checkbox input:checked ~ .checkmark {
    /*background-color: #2196F3;*/
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.clc-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.clc-checkbox .checkmark:after {
    left: 11px;
    top: -10px;
    width: 10px;
    height: 30px;
    border: solid #fab30d;
    border-width: 0 4px 4px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.groupName {
    color: #fab30d;
    text-align: center;

    font-weight: bolder;
    font-size: 20px;

    display: inline-block;
    padding-left: 23%;

    padding-top: 40px;


}

.display-none{
    display: none;
}

.group-content {
    color: #fab30d;
    text-align: center;
    width: 40%;

    font-weight: bolder;
    font-size: 20px;

    display: inline-block;

}

.tariff-description {
    padding-left: 35px;
    font-size: 22px;
}


.tooltip {
    border: 1px solid white;
    background-color: white;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    text-align: center;
    margin: 0 auto;
}


/*left position*/
.tooltipleft {
    position: relative;
    display: inline-block;
    border: 1px solid black;
    background-color: black;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    text-align: center;
    vertical-align: bottom;
    margin-left: 10px;
    margin-bottom: 9px;
    color: white;

}

.tooltipleft .toolttextleft {
    visibility: hidden;
    width: 200px;
    background-color: rgba(105, 105, 105, 1);
    color: #fefefe;
    border: 1px solid black;
    box-shadow: 3px 1px 5px rgba(105, 105, 105, 0.36);
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    font-size: 13px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 130%;
    top: -100%;

}

.tooltipleft:hover .toolttextleft {
    visibility: visible;
}

.info-icon {
    visibility: hidden;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
}

.tariff-description:hover .info-icon {
    visibility: visible;
}

.info-icon .toolttextleft {
    visibility: hidden;
    width: 200px;
    background-color: rgba(14, 14, 14, 0.9);
    color: #fefefe;
    border: 1px solid #fab30d;
    text-align: center;
    padding: 5px;
    font-size: 13px;
    line-height: 13px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -300%;
    top: -200%;

}

.info-icon:hover .toolttextleft {
    visibility: visible;
}

.group-list {
    width: 30%;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-right: 10px;

}

.group-setting {
    width: 60%;
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.68);

}

.error {
    color: red;
    font-style: italic;

}

.common-checkbox {
    display: inline-block;
    padding-left: 40px;
    vertical-align: top;
    width: 10%;
    min-width: 10vw;

}

.clc-arrow-next {
    width: 100%;
    align-content: center;
    text-align: center;
}

.calculator-confirm-btn {
    padding-bottom: 30px;
}

.rotate0  img{
    animation-name: rotateCard;
    animation-duration: 0.5s;
}
.rotate1  img{
    animation-name: rotateCard;
    animation-duration: 0.5s;
}

@keyframes rotateCard {
    from {
        transform: perspective(600px) rotateY(-180deg);
    }
    to {
        transform: perspective(600px) rotateY(0deg);
    }
}

.calculator-radio {
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    color: #fab30d;
}

.calculator-radio label {
    padding-right: 40px;
    padding-left: 5px;
    cursor: pointer;
}

input[type=radio] {
    appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 1px solid #fefefe;
    transition: 0.2s all linear;
    margin-right: 5px;

    position: relative;
    top: 4px;
}

.checked input[type=radio]:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checked input[type=radio]:after {
    display: block;
}

/* Style the checkmark/indicator */
.checked input[type=radio]:after {
    left: 8px;
    top: -15px;
    width: 9px;
    height: 26px;
    border: solid #fab30d;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
}

.small-height .cm-btn .cm-btn-text {
    line-height: 18px;
}

.request-sended {
    font-size: 34px;
    text-align: center;
    padding: 69px;
    color: #ffffff;
}

.phone-input input {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #eb9c34;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 10px 0;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
    -webkit-box-sizing: border-box;
    -moz-box-sizing: border-box;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
}

.test-result {
    width: 60%;
    margin: 0 auto;
}

.test-question {
    text-align: center;
    padding: 10px;
}

.test-answer {
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.pop-up-full-screen.tariff-popup-description {
    left: 0;
    width: 100%;
}

.support-open-full.icon {
    position: fixed;
    background-color: #fff;
    background-size: 14px;
    top: 50px;
    right: 40vw;
    animation-name: showDescriptionBtn;
    animation-duration: 1.3s;
}

.support-iframe-close.icon, .support-iframe__open.icon {
    position: fixed;
    background-color: #fff;
    background-size: 14px;
    top: 12px;
    right: 40vw;
}


.tariff-popup-description-hide .support-iframe-close.icon {
    right: -10vw;
    animation-name: hideDescriptionBtn;
    animation-duration: 1.2s;
}

.tariff-popup-description-inactive .support-iframe-close.icon,
.tariff-popup-description-inactive .support-open-full.icon,
.tariff-popup-description-hide .support-open-full.icon,
.pop-up-full-screen .support-open-full.icon{
    display: none;
}

.tariff-popup-description .support-iframe-close.icon {
    right: 40vw;
    animation-name: showDescriptionBtn;
    animation-duration: 1.3s;
}

.pop-up-full-screen .support-iframe-close.icon {
    right: 40px !important;
    border: 1px solid black;
}

.icon-close-content {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.207 2.207A1 1 0 0 0 11.793.793L7 5.586 2.207.793A1 1 0 0 0 .793 2.207L5.586 7 .793 11.793a1 1 0 1 0 1.414 1.414L7 8.414l4.793 4.793a1 1 0 0 0 1.414-1.414L8.414 7l4.793-4.793z' fill='%232F3747'/%3E%3C/svg%3E") center center no-repeat
}

.icon-open-new-page {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 9.503a1 1 0 0 1 1 1v3a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h3a1 1 0 0 1 0 2H2v10h10v-2.5a1 1 0 0 1 1-1zm-1.7-6.716v-.323l-1.15.314a6.259 6.259 0 0 1-1.65.221c-.6 0-1-.447-1-1a1 1 0 0 1 1-1H13a1 1 0 0 1 1 1v4.504a1 1 0 0 1-1 1c-.552 0-1-.403-1-1a6.3 6.3 0 0 1 .222-1.656l.313-1.147h-.32l-1.078 1.437a10 10 0 0 1-.93 1.072l-3.5 3.498a1 1 0 1 1-1.414-1.414l3.5-3.5a10 10 0 0 1 1.065-.923L11.3 2.787z' fill='%232F3747'/%3E%3C/svg%3E") center center no-repeat
}

.icon {
    position: relative;
    vertical-align: middle;
    display: inline-block;
}

.support-iframe-close {
    top: 12px;
}
.support-open-full {
    top: 50px;
}

.support-iframe-close, .support-open-full {
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    right: calc(100% + 12px);
}

.support-iframe-close:hover, .support-open-full:hover {
    border: 2px solid grey;
}

.support-iframe-close::before, .support-open-full::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: 50%;
    z-index: -1;
}

.icon:after {
    visibility: hidden;
    content: '\00A0';
}


.tariff-popup-description-content{

}

.tariff-popup-description {
    display: block;
    position: fixed;
    left: 60%;
    top: 0;
    width: 40vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
    animation-name: showDescription;
    animation-duration: 1s;
}

.tariff-popup-description-hide {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    width: 0;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
    animation-name: hideDescription;
    animation-duration: 1s;
}

.tariff-popup-description-inactive {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    width: 0;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
}

.pop-up-br {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5000;
}


@keyframes hideDescriptionBtn {
    0% {
        right: 40vw;

    }
    83% {
        right: 0;
    }
    100% {
        right: -10vw;
    }
}
@keyframes showDescriptionBtn {
    0% {
        right: -10vw;
    }
    99% {
        right: -10vw;
    }

    100% {
        right: 40vw;
    }
}

.pop-up-image{

}

.pop-up-image-full-screen-btn {
    position: absolute;
    right: 30px;
    margin-top: 15px;
    cursor: pointer;
    visibility: hidden;
}

.pop-up-image:hover .pop-up-image-full-screen-btn {
    visibility: visible;
}

.pop-up-image-full-screen-btn:hover svg {
    width: 30px;
    height: 30px;
}
.pop-up-image-full-screen-btn:hover {
   margin-right: -2px;
    margin-top: 12px;
}

.pop-up-image-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 60vw;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    @keyframes showDescription {
        from {
            left: 100%;
        }
        to {
            left: 60vw;
        }
    }

    @keyframes hideDescription {
        from {
            left: 60%;
            width: 40vw;
        }
        to {
            left: 100vw;
            width: 40vw;
        }
    }
    .btn-open-pop-up {
        position: fixed;
        top: 100px;
        right: 10px;
        cursor: pointer;
    }

    .calculator-title {
        font-size: 80px;
    }

    .confirm-options {
        padding: 0 100px;
    }

    .modal-confirm-btn {

        width: 50vw;
        margin: 0 auto;

    }

    .calculate-tariff-step {
        width: 75%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        margin-bottom: 150px;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094
    }

    .calculate-tariff-step-big {
        width: 75%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        margin-bottom: 150px;
        height: 70vh;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
    }

    .calculator-img {
        display: table-cell;
        width: 35%;
        height: 100%;
        text-align: center;
        margin: 0 auto;
        vertical-align: middle;
        padding: 20px 0 20px 0;
    }

    .calculator-img-border1 {
        border: 3px solid #fab30d;
        border-radius: 50%;
        width: 270px;
        height: 270px;
        margin: 0 auto;
    }

    .calculator-img-border2 {
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 259px;
        height: 259px;
        margin: 3px auto;
    }

    .calculator-img-border2 img {
        width: 250px;
    }

    .calculator-options {
        width: 50%;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        height: 100%;
    }

    .calculator-options-values {
        height: 50vh;
        display: table-cell;
        vertical-align: middle;
    }
    .calculator-options-button {
        height: 10vh;
    }

    .calculator-options .mp-btn {
        top: 0
    }

    .test-options {
        margin: 0 auto;
        height: 40vh;
        width: 75%;
    }

    .test-options2 {
        margin: 0 auto;
        height: 40vh;
        width: 45%;
        padding-left: 20%;
        display: inline-block;
    }

    .calculator-next-step {
        width: 20%;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        padding-right: 10px;
        border-left: 1px solid #ffffff26;
    }

    .calc-previous {
        display: none;
    }

    .calc-next {
        display: none;
    }

    .desctop {
        display: block;
    }

    .phone {
        display: none;
    }

    .result-selected {
        width: 40%;

    }

    .test-image-block {
        display: inline-flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        padding: 5px;
        height: 40vh;
        width: calc(75% / 4) ;
    }

    .test-image-block div img {
        height: 20vw;
        padding: 10px;
        vertical-align: auto;
        cursor: pointer;

    }
    .area .calculator-radio label {
        padding-right: 40px;
    }

    .test-question {
        height: 15vh;
    }

    .result-button-calc{
        display: inline-block !important;
        width: 50%;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {

    .tariff-popup-description {
        width: 100%;
    }

    @keyframes showDescription {
        from {
            left: 100%;
        }
        to {
            left: 0;
        }
    }

    @keyframes hideDescription {
        0% {
            left: 0;
            width: 100%;

        }
        99% {

            width: 100%;
        }
        100% {
            left: 100vw;
            width: 0;
        }
    }

    .support-iframe-close.icon {
        right: 10px !important;
        border: 1px solid black;
    }

    .tariff-popup-description-inactive .support-iframe-close.icon,
    .tariff-popup-description-hide .support-iframe-close.icon{
        display: none;
    }

    .support-open-full.icon{
        display: none;
    }

    .tariff-popup-description {
        left: 0;
        width: 100%;
    }

    .btn-open-pop-up {
        position: fixed;
        top: 90px;
        right: 10px;
        cursor: pointer;
    }

    .mobile-hide{
        display: none;
    }

    .pb-chb-mobile-10 {
        padding-bottom: 7px;
    }

    .tariff-description,
    .clc-checkbox {
        font-size: 16px;
    }

    .calculator-radio {
        text-align: left;
    }

    .calculator-radio label {
        width: 90%;
    }
    .area .calculator-radio label {
        width: 50%;
        padding-right: 0;
    }

    .test-image-block {
        display: inline-block;
        width: 50%;
        padding: 5px;
    }

    .test-image-block img {
        width: 40vw;
        padding: 10px;
        cursor: pointer;
    }

    .desctop {
        display: none;
    }

    .phone {
        display: block;
    }

    .calculator-title {
        padding: 0px;

    }

    .calculator-title h1 {
        font-size: 29px;
    }

    .calculator-top {
        padding: 10px 0 30px 0;
    }

    .calculator-all-step-conteiner {
        position: relative;
        top: -30px;
    }

    .calculator-all-step {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
    }

    .calculate-tariff-step {
        width: 100vw;
        height: 68vh;
        margin: 0 10vw;
        /*!*margin: 0 auto;*!*/
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
        /*display: inline-block;*/
        display: flex;
        justify-content: center;
        flex: 0 0 80vw;
        scroll-snap-align: start;
    }

    .calculate-tariff-step-big {
        width: 100vw;
        margin: 0 10vw;
        /*!*margin: 0 auto;*!*/
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;

        height: 68vh;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
        /*display: inline-block;*/
        /*scroll-snap-align: start;*/
        display: flex;
        justify-content: center;
        scroll-snap-align: start;

    }

    .calculator-img {
        height: 18vh;
        text-align: center;
        margin: 0 auto;
        vertical-align: middle;
        padding: 20px 0 20px 0;
    }

    .calculator-img-border1 {
        border: 3px solid #fab30d;
        border-radius: 50%;
        width: 13.5vh;
        height: 13.5vh;
        margin: 0 auto;
    }

    .calculator-img-border2 {
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 13vh;
        height: 13vh;
        margin: 0.2vh auto;
    }

    .calculator-img-border2 img {
        width: 12vh;
    }


    .calculator-options {
        vertical-align: middle;
        margin: 0 auto;
        height: 40vh;
        width: 90vw;
        padding: 0 5px;
    }

    .calculate-tariff-result{
        width: 100%;
    }
    .calculate-tariff-result div div .calculator-options {
        height: unset;

    }

    .test-options {
        vertical-align: middle;
        margin: 0 auto;
    }

    .calculator-next-step {
        display: none;
        vertical-align: middle;
        margin: 0 auto;
        padding-right: 10px;
    }

    .calc-previous {
        height: 18vh;
        rotate: 90deg;
        scroll-snap-align: start;
        position: relative;
        top: -16vh;
        left: -40vw;
        display: inline-block;
    }

    .calc-next {
        height: 18vh;
        rotate: 270deg;
        position: relative;
        top: -16vh;
        left: 40vw;
        display: inline-block;
    }

    /*.calc-next div {*/
    /*    position: relative;*/
    /*    top: 89vw;*/
    /*}*/

    /*.calc-previous div {*/
    /*    position: relative;*/
    /*    top: -10px;*/
    /*}*/

    .calculator-confirm-btn {
        position: relative;
        top: -5vh;
    }

    .calculator-modal {
        left: 5%;
        width: 90%;
    }

    .phone-btn {
        display: block;
    }

    .phone-btn div {
        display: block !important;
        padding: 0 !important;
        margin: 10px auto;
        padding-left: 1vw !important;
    }

    .calculator-price {
        font-size: 23px;
        padding-bottom: 10px;
    }


    .tooltipleft .toolttextleft {
        left: -90px;
        top: 120%;
    }

    .request-sended {
        font-size: 34px;
        text-align: center;
        padding: 69px 0;
    }
    .test-result {
        width: 100%;
    }

    .phone-input input  {
        margin: 5px 0;
        height: 45px;
    }

    .test-question {
        font-size: 20px;
    }

    .test-title h1 {
        font-size: 30px;
    }
}


