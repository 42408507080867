.project-title {
    position: absolute;
    color: white;
    padding: 20px;
    opacity: 1 !important;
    text-align: left;
    font-size: 25px;
    vertical-align: bottom;
    display: table-cell;
    bottom: 20%;
}

a.project-box-hover {
    float: left;
    text-decoration: none;
    position: relative;
}

a.project-box-hover:hover .project-img-fon {
    opacity: 0.8;
}

a.project-box-hover .project-img-fon {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) .24%, rgb(0, 0, 0) 100%) !important;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.8s ease;
    opacity: 0.0;
}

.project-box-hover .info {
    opacity: 0;
}

.project-box-hover:hover .info {
    opacity: 1;
}

.project-box-hover .info .line,
.project-box-hover .info .read-more {
    opacity: 0;
}

.project-box-hover:hover .info .line,
.project-box-hover:hover .info .read-more {
    opacity: 1 !important;
}

.project-box-hover:hover .info .line {
    width: 80%;
}

.line {
    position: absolute;
    bottom: 20%;
    width: 0;
    transition: width 0.2s;
    height: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(245, 162, 9);
    opacity: 1 !important;
}

.read-more {
    position: absolute;
    bottom: 15%;
    width: 80%;
    height: 3px;
    color: white;
    border: none !important;
    padding-left: 20px;
    opacity: 1 !important;
}

span.arr {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid #ffffff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}

/*Для большого экрана*/
@media only screen and (min-width: 951px) {
    .project-box {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 7px);
        height: calc(100vw * 0.98 / 3 - 12px);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-1 * 100vw * 0.98 / 3 + 7px);
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 7px);
        height: calc(100vw * 0.98 / 3 - 10px);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.95 / 3);
    }

    .project-box-hover .info {
        opacity: 0;
    }

    .project-box-hover:hover .info {
        margin-top: -200px;
        opacity: 1;
    }

    .project-box-hover .info .line,
    .project-box-hover .info .read-more {
        opacity: 0;
    }

    .project-box-hover:hover .info .line,
    .project-box-hover:hover .info .read-more {
        opacity: 1 !important;
    }

    .project-box-hover:hover .info .line {
        width: 80%;
    }

    .line {
        position: absolute;
        bottom: 20%;
        width: 0;
        transition: width 0.2s;
        height: 3px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: rgb(245, 162, 9);
        opacity: 1 !important;
    }

    .read-more {
        position: absolute;
        bottom: 15%;
        width: 80%;
        height: 3px;
        color: white;
        border: none !important;
        padding-left: 20px;
        opacity: 1 !important;

    }

    .project-title {
        position: absolute;
        color: white;
        padding: 20px;
        opacity: 1 !important;
        text-align: left;
        font-size: 25px;
        vertical-align: bottom;
        display: table-cell;
        bottom: 20%;
    }

    .project-img {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.95 / 3);
    }


    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .broadcrambs,
    .category-sub-menu-container > .next,
    .category-sub-menu-container > .prev{
        display: none;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {
    .project-box {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 7px);
        height: calc(100vw * 0.95 / 2 + 13px);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-1 * 100vw * 0.98 / 2 + 7px);
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 7px);
        height: calc(100vw * 0.98 / 2 - 10px);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.98 / 2);
    }

    .project-img {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.98 / 2);
        float: left
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .project-title {
        font-size: 20px;
    }

    .broadcrambs,
    .category-sub-menu-container > .next,
    .category-sub-menu-container > .prev{
        display: none;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {
    .project-box {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(95vw);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-96vw);
        margin-bottom: 5px;
        width: calc(96vw);
        height: calc(95vw);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(96vw);
        height: calc(95vw);
    }

    .project-img {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: 95vw;
        float: left
    }

    .project-img-with-description-all {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .project-title {
        font-size: 15px;
    }

    a.project-box-hover-phone .project-img-fon {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) .24%, rgb(0, 0, 0) 100%) !important;
        background-color: rgba(0, 0, 0, 0.8);
        transition: opacity 0.8s ease;
        opacity: 0.8;
    }

    .project-box-hover-phone .info {
        opacity: 1;
    }

    .project-box-hover-phone .info .line,
    .project-box-hover-phone .info .read-more {
        opacity: 1 !important;
    }

    .project-box-hover-phone .info .line {
        width: 80%;
    }

    .category-sub-menu-container {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
    }

    .category-sub-menu-item {
        display: flex;
        justify-content: center;
        scroll-snap-align: start;
        padding-left: 15px;
    }

    .category-sub-menu-container > .prev svg {
        rotate: 90deg;
        position: absolute;
        left: -5px;
        margin: 10px -15px 0 -5px;
        background-color: white;
        padding: 5px;
    }

    .category-sub-menu-container > .next svg {
        rotate: 270deg;
        position: absolute;
        right: -10px;
        margin: 9px 0 0 0;
        background-color: white;
        padding: 5px;
    }
}

.broadcrambs {
    /*text-transform: uppercase;*/
    padding: 10px;
    font-size: 20px;


}
.broadcrambs a {
    color: rgb(0, 0, 0);
}

.inline-block {
    display: inline-block;
}
.admin-link {
    padding: 20px;
}

.categories-list {
    width: 20%;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-right: 10px;
}

.categories-setting {
    width: 75%;
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.68);
}

.error {
    color: red;
    font-style: italic;
}

.sub-menu {
    text-align: center;
}

.sub-menu a {
    color: black;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.sub-menu a:hover {
    color: #fab30d;
    text-transform: uppercase;
    text-decoration: #fab30d underline;

}

