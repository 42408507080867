.cm-btn_effect {
    background: -webkit-gradient(linear, left top, right top, from(rgba(255, 255, 255, .1)), to(rgba(255, 255, 255, .4)));
    background: -webkit-linear-gradient(left, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    background: -o-linear-gradient(left, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    -webkit-transform: skewX(-45deg);
    -ms-transform: skewX(-45deg);
    transform: skewX(-45deg);
}

.cm-btn {
    margin: 0 auto;
    width: 285px;
    height: 60px;
    border: 2px solid #fab30d;
    background-color: #fab30d;
    border-radius: 10px;
    display: block;
    overflow:hidden;
    cursor: pointer;
    color: white;
    box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.cm-btn-small {
    width: 250px;
    height: 50px;
    border: 2px solid #fab30d;
    margin: 0 auto;
}

.cm-btn-text {
    width: 285px;
    height: 60px;
    position: absolute;
    top: 0;
    text-align: center;
    font-size: 20px;
    padding-top: 13px;
    color: white;
}

.cm-btn-text-small {
    width: 250px;
    height: 50px;
    padding-top: 10px;
}

.cm-btn:hover {
    background-color: rgba(4, 3, 0, 0.68);
    -webkit-transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
    transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.cm-btn-animation{
    display: table-cell;
    height: 60px;
    transform: translateX(-100px);
    animation: shiftByX 5s ease-in-out infinite;
}

@keyframes shiftByX {
    30% {
        -webkit-transform: translateX(-300px);
        transform: translateX(-300px);
    }
    100% {
        -webkit-transform: translateX(400px);
        transform: translateX(400px);
    }
}