
.sun-editor {
    width: auto;
    height: auto;
    box-sizing: border-box;
    border: 1px solid #dadada;
    background-color: #fff;
    color: #000;
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.sun-editor * {
    box-sizing: border-box;
    -webkit-user-drag: none;
    overflow: visible
}

.sun-editor-common button, .sun-editor-common input, .sun-editor-common select, .sun-editor-common textarea {
    font-size: 14px;
    line-height: 1.5
}

.sun-editor-common blockquote, .sun-editor-common body, .sun-editor-common button, .sun-editor-common code, .sun-editor-common dd, .sun-editor-common div, .sun-editor-common dl, .sun-editor-common dt, .sun-editor-common fieldset, .sun-editor-common form, .sun-editor-common h1, .sun-editor-common h2, .sun-editor-common h3, .sun-editor-common h4, .sun-editor-common h5, .sun-editor-common h6, .sun-editor-common input, .sun-editor-common legend, .sun-editor-common li, .sun-editor-common ol, .sun-editor-common p, .sun-editor-common pre, .sun-editor-common select, .sun-editor-common td, .sun-editor-common textarea, .sun-editor-common th, .sun-editor-common ul {
    margin: 0;
    padding: 0;
    border: 0
}

.sun-editor-common dl, .sun-editor-common li, .sun-editor-common menu, .sun-editor-common ol, .sun-editor-common ul {
    list-style: none !important
}

.sun-editor-common hr {
    margin: 6px 0 !important
}

.sun-editor textarea {
    resize: none;
    border: 0;
    padding: 0
}

.sun-editor button {
    border: 0;
    background-color: transparent;
    touch-action: manipulation;
    cursor: pointer;
    outline: none
}

.sun-editor button, .sun-editor input, .sun-editor select, .sun-editor textarea {
    vertical-align: middle
}

.sun-editor button span {
    display: block;
    margin: 0;
    padding: 0
}

.sun-editor button .txt {
    display: block;
    margin-top: 0;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis
}

.sun-editor button * {
    pointer-events: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden
}

.sun-editor .se-svg, .sun-editor button > svg {
    width: 16px;
    height: 16px;
    margin: auto;
    fill: currentColor;
    display: block;
    text-align: center;
    float: none
}

.sun-editor .close > svg, .sun-editor .se-dialog-close > svg {
    width: 10px;
    height: 10px
}

.sun-editor .se-btn-select > svg {
    float: right;
    width: 10px;
    height: 10px
}

.sun-editor .se-btn-list > .se-list-icon {
    display: inline-block;
    width: 16px;
    height: 16px;
    margin: -1px 10px 0 0;
    vertical-align: middle
}

.sun-editor .se-line-breaker > button > svg {
    width: 24px;
    height: 24px
}

.sun-editor button > i:before {
    -moz-osx-font-smoothing: grayscale;
    -webkit-font-smoothing: antialiased;
    display: inline-block;
    font-style: normal;
    font-feature-settings: normal;
    font-variant: normal;
    text-rendering: auto;
    font-size: 15px;
    line-height: 2
}

.sun-editor button > [class=se-icon-text] {
    font-size: 20px;
    line-height: 1
}

.sun-editor .se-arrow, .sun-editor .se-arrow:after {
    position: absolute;
    display: block;
    width: 0;
    height: 0;
    border: 11px solid transparent
}

.sun-editor .se-arrow.se-arrow-up {
    top: -11px;
    left: 20px;
    margin-left: -11px;
    border-top-width: 0;
    border-bottom-color: #dadada
}

.sun-editor .se-arrow.se-arrow-up:after {
    top: 1px;
    margin-left: -11px;
    content: " ";
    border-top-width: 0;
    border-bottom-color: #fff
}

.sun-editor .se-toolbar .se-arrow.se-arrow-up:after {
    border-bottom-color: #fafafa
}

.sun-editor .se-arrow.se-arrow-down {
    top: 0;
    left: 0;
    margin-left: -11px;
    border-bottom-width: 0;
    border-top-color: #dadada
}

.sun-editor .se-arrow.se-arrow-down:after {
    top: -12px;
    margin-left: -11px;
    content: " ";
    border-bottom-width: 0;
    border-top-color: #fff
}

.sun-editor .se-toolbar .se-arrow.se-arrow-down:after {
    border-top-color: #fafafa
}

.sun-editor .se-container {
    position: relative;
    width: 100%;
    height: 100%
}

.sun-editor button {
    color: #000
}

.sun-editor .se-btn {
    float: left;
    width: 34px;
    height: 34px;
    border: 0;
    border-radius: 4px;
    margin: 1px !important;
    padding: 0;
    font-size: 12px;
    line-height: 27px
}

.sun-editor .se-btn:enabled:focus, .sun-editor .se-btn:enabled:hover {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-btn:enabled:active {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-btn-primary {
    color: #000;
    background-color: #c7deff;
    border: 1px solid #80bdff;
    border-radius: 4px
}

.sun-editor .se-btn-primary:focus, .sun-editor .se-btn-primary:hover {
    color: #000;
    background-color: #80bdff;
    border-color: #3f9dff;
    outline: 0 none
}

.sun-editor .se-btn-primary:active {
    color: #fff;
    background-color: #3f9dff;
    border-color: #4592ff;
    box-shadow: inset 0 3px 5px #4592ff
}

.sun-editor input, .sun-editor select, .sun-editor textarea {
    color: #000;
    border: 1px solid #ccc;
    border-radius: 4px
}

.sun-editor input:focus, .sun-editor select:focus, .sun-editor textarea:focus {
    border: 1px solid #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem #c7deff;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.sun-editor .se-btn:enabled.active {
    color: #4592ff;
    outline: 0 none
}

.sun-editor .se-btn:enabled.active:focus, .sun-editor .se-btn:enabled.active:hover {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-btn:enabled.active:active {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-btn:enabled.on {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-btn:enabled.on:focus, .sun-editor .se-btn:enabled.on:hover {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    outline: 0 none
}

.sun-editor .se-btn:enabled.on:active {
    background-color: #c1c1c1;
    border-color: #b1b1b1;
    box-shadow: inset 0 3px 5px #b1b1b1
}

.sun-editor .se-btn-list:disabled, .sun-editor .se-btn:disabled, .sun-editor button:disabled {
    cursor: not-allowed;
    background-color: inherit;
    color: #bdbdbd
}

.sun-editor .se-loading-box {
    position: absolute;
    display: none;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fff;
    opacity: .7;
    filter: alpha(opacity=70);
    z-index: 2147483647
}

.sun-editor .se-loading-box .se-loading-effect {
    position: absolute;
    display: block;
    top: 50%;
    left: 50%;
    height: 25px;
    width: 25px;
    border-top: 2px solid #07d;
    border-right: 2px solid transparent;
    border-radius: 50%;
    animation: spinner .8s linear infinite;
    margin: -25px 0 0 -25px
}

.sun-editor .se-line-breaker {
    position: absolute;
    display: none;
    width: 100%;
    height: 1px;
    cursor: text;
    border-top: 1px solid #3288ff;
    z-index: 7
}

.sun-editor .se-line-breaker > button.se-btn {
    position: relative;
    display: inline-block;
    width: 30px;
    height: 30px;
    top: -15px;
    float: none;
    left: -50%;
    background-color: #fff;
    border: 1px solid #0c2240;
    opacity: .6;
    cursor: pointer
}

.sun-editor .se-line-breaker > button.se-btn:hover {
    opacity: .9;
    background-color: #fff;
    border-color: #041b39
}

.sun-editor .se-line-breaker-component {
    position: absolute;
    display: none;
    width: 24px;
    height: 24px;
    background-color: #fff;
    border: 1px solid #0c2240;
    opacity: .6;
    border-radius: 4px;
    cursor: pointer;
    z-index: 7
}

.sun-editor .se-line-breaker-component:hover {
    opacity: .9
}

.sun-editor .se-toolbar {
    display: block;
    position: relative;
    height: auto;
    width: 100%;
    overflow: visible;
    padding: 0;
    margin: 0;
    background-color: #fafafa;
    outline: 1px solid #dadada;
    z-index: 5
}

.sun-editor .se-toolbar-cover {
    position: absolute;
    display: none;
    font-size: 36px;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background-color: #fefefe;
    opacity: .5;
    filter: alpha(opacity=50);
    cursor: not-allowed;
    z-index: 4
}

.sun-editor .se-toolbar-separator-vertical {
    display: inline-block;
    height: 0;
    width: 0;
    margin: 0;
    vertical-align: top
}

.sun-editor .se-toolbar.se-toolbar-balloon, .sun-editor .se-toolbar.se-toolbar-inline {
    display: none;
    position: absolute;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    -webkit-box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.sun-editor .se-toolbar.se-toolbar-balloon {
    z-index: 2147483647;
    width: auto
}

.sun-editor .se-toolbar.se-toolbar-sticky {
    position: fixed;
    top: 0
}

.sun-editor .se-toolbar-sticky-dummy {
    display: none;
    position: static;
    z-index: -1
}

.sun-editor .se-btn-module {
    display: inline-block
}

.sun-editor .se-btn-module-border {
    border: 1px solid #dadada;
    border-radius: 4px;
    margin-left: 1px;
    margin-right: 1px
}

.sun-editor .se-btn-module-enter {
    display: block;
    width: 100%;
    height: 0;
    margin: 0;
    padding: 0;
    background-color: transparent
}

.sun-editor .se-toolbar-more-layer {
    margin: 0 -3px;
    background-color: #fafafa
}

.sun-editor .se-toolbar-more-layer .se-more-layer {
    display: none;
    border-top: 1px solid #dadada
}

.sun-editor .se-toolbar-more-layer .se-more-layer .se-more-form {
    display: inline-block;
    width: 100%;
    height: auto;
    padding: 4px 3px 0
}

.sun-editor .se-btn-module .se-btn-more.se-btn-more-text {
    width: auto;
    padding: 0 4px
}

.sun-editor .se-btn-module .se-btn-more:focus, .sun-editor .se-btn-module .se-btn-more:hover {
    color: #000;
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    outline: 0 none
}

.sun-editor .se-btn-module .se-btn-more.on {
    color: #333;
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    outline: 0 none
}

.sun-editor .se-btn-module .se-btn-more.on:hover {
    color: #000;
    background-color: #c1c1c1;
    border-color: #b1b1b1;
    outline: 0 none
}

.sun-editor .se-menu-list, .sun-editor .se-menu-list li {
    float: left;
    padding: 0;
    margin: 0
}

.sun-editor .se-menu-list li {
    position: relative
}

.sun-editor .se-btn-select {
    width: auto;
    display: flex;
    padding: 4px 6px
}

.sun-editor .se-btn-select .txt {
    flex: auto;
    text-align: left
}

.sun-editor .se-btn-select.se-btn-tool-font {
    width: 100px
}

.sun-editor .se-btn-select.se-btn-tool-format {
    width: 82px
}

.sun-editor .se-btn-select.se-btn-tool-size {
    width: 78px
}

.sun-editor .se-btn-tray {
    position: relative;
    width: 100%;
    height: auto;
    padding: 4px 3px 0;
    margin: 0
}

.sun-editor .se-menu-tray {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 0
}

.sun-editor .se-submenu {
    overflow-x: hidden;
    overflow-y: auto
}

.sun-editor .se-menu-container {
    overflow-x: unset;
    overflow-y: unset
}

.sun-editor .se-list-layer {
    display: none;
    position: absolute;
    top: 0;
    left: 0;
    height: auto;
    z-index: 5;
    border: 1px solid #bababa;
    border-radius: 4px;
    padding: 6px 0;
    background-color: #fff;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    outline: 0 none
}

.sun-editor .se-list-layer .se-list-inner {
    padding: 0;
    margin: 0;
    overflow-x: initial;
    overflow-y: initial;
    overflow: visible
}

.sun-editor .se-list-layer button {
    margin: 0;
    width: 100%
}

.sun-editor .se-list-inner ul {
    width: 100%;
    padding: 0
}

.sun-editor .se-list-inner li > button {
    min-width: 100%;
    width: -webkit-max-content;
    width: max-content
}

.sun-editor .se-list-inner .se-list-basic li {
    width: 100%
}

.sun-editor .se-list-inner .se-list-basic li button.active {
    background-color: #80bdff;
    border: 1px solid #3f9dff;
    border-left: 0;
    border-right: 0
}

.sun-editor .se-list-inner .se-list-basic li button.active:hover {
    background-color: #3f9dff;
    border: 1px solid #4592ff;
    border-left: 0;
    border-right: 0
}

.sun-editor .se-list-inner .se-list-basic li button.active:active {
    background-color: #4592ff;
    border: 1px solid #407dd1;
    border-left: 0;
    border-right: 0;
    box-shadow: inset 0 3px 5px #407dd1
}

.sun-editor .se-list-inner .se-list-checked li button > .se-svg {
    float: left;
    padding: 6px 6px 0 0
}

.sun-editor .se-list-inner .se-list-checked li button > .se-svg > svg {
    display: none
}

.sun-editor .se-list-inner .se-list-checked li button.se-checked {
    color: #4592ff
}

.sun-editor .se-list-inner .se-list-checked li button.se-checked > .se-svg > svg {
    display: block
}

.sun-editor .se-btn-list {
    width: 100%;
    height: auto;
    min-height: 32px;
    padding: 0 14px;
    cursor: pointer;
    font-size: 12px;
    line-height: normal;
    text-indent: 0;
    text-decoration: none;
    text-align: left
}

.sun-editor .se-btn-list.default_value {
    background-color: #f3f3f3;
    border-top: 1px dotted #b1b1b1;
    border-bottom: 1px dotted #b1b1b1
}

.sun-editor .se-btn-list:focus, .sun-editor .se-btn-list:hover {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-btn-list:active {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-list-layer.se-list-font-size {
    min-width: 140px;
    max-height: 300px
}

.sun-editor .se-list-layer.se-list-font-family {
    min-width: 156px
}

.sun-editor .se-list-layer.se-list-font-family .default {
    border-bottom: 1px solid #ccc
}

.sun-editor .se-list-layer.se-list-line {
    width: 125px
}

.sun-editor .se-list-layer.se-list-line hr {
    border-width: 1px 0 0;
    height: 1px
}

.sun-editor .se-list-layer.se-list-align .se-list-inner {
    left: 9px;
    width: 125px
}

.sun-editor .se-list-layer.se-list-format {
    min-width: 156px
}

.sun-editor .se-list-layer.se-list-format li {
    padding: 0;
    width: 100%
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list {
    line-height: 100%
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value=h1] {
    height: 40px
}

.sun-editor .se-list-layer.se-list-format ul .se-btn-list[data-value=h2] {
    height: 34px
}

.sun-editor .se-list-layer.se-list-format ul p {
    font-size: 13px
}

.sun-editor .se-list-layer.se-list-format ul div {
    font-size: 13px;
    padding: 4px 2px
}

.sun-editor .se-list-layer.se-list-format ul h1 {
    font-size: 2em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul h2 {
    font-size: 1.5em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul h3 {
    font-size: 1.17em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul h4 {
    font-size: 1em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul h5 {
    font-size: .83em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul h6 {
    font-size: .67em;
    font-weight: 700;
    color: #333
}

.sun-editor .se-list-layer.se-list-format ul blockquote {
    font-size: 13px;
    color: #999;
    height: 22px;
    margin: 0;
    background-color: transparent;
    line-height: 1.5;
    border-color: #b1b1b1;
    padding: 0 0 0 7px;
    border-left: 5px #b1b1b1;
    border-style: solid
}

.sun-editor .se-list-layer.se-list-format ul pre {
    font-size: 13px;
    color: #666;
    padding: 4px 11px;
    margin: 0;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 4px
}

.sun-editor .se-selector-table {
    display: none;
    position: absolute;
    top: 34px;
    left: 1px;
    z-index: 5;
    padding: 5px 0;
    float: left;
    margin: 2px 0 0;
    font-size: 14px;
    text-align: left;
    list-style: none;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid #ccc;
    border-radius: 4px;
    box-shadow: 0 6px 12px rgba(0, 0, 0, .175)
}

.sun-editor .se-selector-table .se-table-size {
    font-size: 18px;
    padding: 0 5px
}

.sun-editor .se-selector-table .se-table-size-picker {
    position: absolute !important;
    z-index: 3;
    font-size: 18px;
    width: 10em;
    height: 10em;
    cursor: pointer
}

.sun-editor .se-selector-table .se-table-size-highlighted {
    position: absolute !important;
    z-index: 2;
    font-size: 18px;
    width: 1em;
    height: 1em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASCAYAAABWzo5XAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsQAAA7EAZUrDhsAAAAZdEVYdFNvZnR3YXJlAEFkb2JlIEltYWdlUmVhZHlxyWU8AAADJmlUWHRYTUw6Y29tLmFkb2JlLnhtcAAAAAAAPD94cGFja2V0IGJlZ2luPSLvu78iIGlkPSJXNU0wTXBDZWhpSHpyZVN6TlRjemtjOWQiPz4gPHg6eG1wbWV0YSB4bWxuczp4PSJhZG9iZTpuczptZXRhLyIgeDp4bXB0az0iQWRvYmUgWE1QIENvcmUgNS42LWMxNDIgNzkuMTYwOTI0LCAyMDE3LzA3LzEzLTAxOjA2OjM5ICAgICAgICAiPiA8cmRmOlJERiB4bWxuczpyZGY9Imh0dHA6Ly93d3cudzMub3JnLzE5OTkvMDIvMjItcmRmLXN5bnRheC1ucyMiPiA8cmRmOkRlc2NyaXB0aW9uIHJkZjphYm91dD0iIiB4bWxuczp4bXBNTT0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wL21tLyIgeG1sbnM6c3RSZWY9Imh0dHA6Ly9ucy5hZG9iZS5jb20veGFwLzEuMC9zVHlwZS9SZXNvdXJjZVJlZiMiIHhtbG5zOnhtcD0iaHR0cDovL25zLmFkb2JlLmNvbS94YXAvMS4wLyIgeG1wTU06RG9jdW1lbnRJRD0ieG1wLmRpZDo4QTZCNzMzN0I3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wTU06SW5zdGFuY2VJRD0ieG1wLmlpZDo4QTZCNzMzNkI3RUYxMUU4ODcwQ0QwMjM1NTgzRTJDNyIgeG1wOkNyZWF0b3JUb29sPSJBZG9iZSBQaG90b3Nob3AgQ0MgMjAxOCAoV2luZG93cykiPiA8eG1wTU06RGVyaXZlZEZyb20gc3RSZWY6aW5zdGFuY2VJRD0ieG1wLmlpZDo0MzYyNEUxRUI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIgc3RSZWY6ZG9jdW1lbnRJRD0ieG1wLmRpZDo0MzYyNEUxRkI3RUUxMUU4ODZGQzgwRjNBODgyNTdFOSIvPiA8L3JkZjpEZXNjcmlwdGlvbj4gPC9yZGY6UkRGPiA8L3g6eG1wbWV0YT4gPD94cGFja2V0IGVuZD0iciI/Pl0yAuwAAABBSURBVDhPY/wPBAxUAGCDGvdBeWSAeicIDTfIXREiQArYeR9hEBOEohyMGkQYjBpEGAxjg6ib+yFMygCVvMbAAABj0hwMTNeKJwAAAABJRU5ErkJggg==") repeat
}

.sun-editor .se-selector-table .se-table-size-unhighlighted {
    position: relative !important;
    z-index: 1;
    font-size: 18px;
    width: 10em;
    height: 10em;
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAASAgMAAAAroGbEAAAACVBMVEUAAIj4+Pjp6ekKlAqjAAAAAXRSTlMAQObYZgAAAAFiS0dEAIgFHUgAAAAJcEhZcwAACxMAAAsTAQCanBgAAAAHdElNRQfYAR0BKhmnaJzPAAAAG0lEQVQI12NgAAOtVatWMTCohoaGUY+EmIkEAEruEzK2J7tvAAAAAElFTkSuQmCC") repeat
}

.sun-editor .se-selector-table .se-table-size-display {
    padding-left: 5px
}

.sun-editor .se-list-layer.se-table-split {
    top: 36px
}

.sun-editor .se-list-layer .se-selector-color {
    display: flex;
    width: -webkit-max-content;
    width: max-content;
    max-width: 270px;
    height: auto;
    padding: 0;
    margin: auto
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet {
    width: 100%;
    height: 100%;
    padding: 0
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet li {
    display: flex;
    float: left;
    position: relative;
    margin: 0
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet button {
    display: block;
    cursor: default;
    width: 30px;
    height: 30px;
    text-indent: -9999px
}

.sun-editor .se-list-layer .se-selector-color .se-color-pallet button.active, .sun-editor .se-list-layer .se-selector-color .se-color-pallet button:focus, .sun-editor .se-list-layer .se-selector-color .se-color-pallet button:hover {
    border: 3px solid #fff
}

.sun-editor .se-form-group {
    display: flex;
    width: 100%;
    min-height: 40px;
    height: auto;
    padding: 4px
}

.sun-editor .se-form-group input {
    flex: auto;
    display: inline-block;
    width: auto;
    height: 33px;
    font-size: 12px;
    margin: 1px 0;
    padding: 0;
    border-radius: .25rem;
    border: 1px solid #ccc
}

.sun-editor .se-form-group button, .sun-editor .se-submenu-form-group button {
    float: right;
    width: 34px;
    height: 34px;
    margin: 0 2px !important
}

.sun-editor .se-form-group button.se-btn {
    border: 1px solid #ccc
}

.sun-editor .se-form-group > div {
    position: relative
}

.sun-editor .se-form-group label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

.sun-editor .se-form-group-label {
    width: 100%;
    height: auto;
    padding: 0 4px
}

.sun-editor .se-form-group-label label {
    font-size: 13px;
    font-weight: 700
}

.sun-editor .se-submenu .se-form-group input {
    width: auto;
    height: 33px;
    color: #555
}

.sun-editor .se-submenu .se-form-group .se-color-input {
    width: 72px;
    text-transform: uppercase;
    border: none;
    border-bottom: 2px solid #b1b1b1;
    outline: none
}

.sun-editor .se-submenu .se-form-group .se-color-input:focus {
    border-bottom: 3px solid #b1b1b1
}

.sun-editor .se-wrapper {
    position: relative !important;
    width: 100%;
    height: auto;
    overflow: hidden;
    z-index: 1
}

.sun-editor .se-wrapper .se-wrapper-inner {
    width: 100%;
    height: 100%;
    min-height: 65px;
    overflow-y: auto;
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
    user-select: auto;
    -o-user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto
}

.sun-editor .se-wrapper .se-wrapper-inner:focus {
    outline: none
}

.sun-editor .se-wrapper .se-wrapper-code {
    background-color: #191919;
    color: #fff;
    font-size: 13px;
    word-break: break-all;
    padding: 4px;
    margin: 0;
    resize: none !important
}

.sun-editor .se-wrapper .se-wrapper-wysiwyg {
    display: block
}

.sun-editor .se-wrapper .se-wrapper-code-mirror {
    font-size: 13px
}

.sun-editor .se-wrapper .se-placeholder {
    position: absolute;
    display: none;
    white-space: nowrap;
    text-overflow: ellipsis;
    z-index: 1;
    color: #b1b1b1;
    font-size: 13px;
    line-height: 1.5;
    top: 0;
    left: 0;
    right: 0;
    overflow: hidden;
    margin-top: 0;
    padding-top: 16px;
    padding-left: 16px;
    margin-left: 0;
    padding-right: 16px;
    margin-right: 0;
    pointer-events: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden
}

.sun-editor .se-resizing-bar {
    display: flex;
    width: auto;
    height: auto;
    min-height: 16px;
    border-top: 1px solid #dadada;
    padding: 0 4px;
    background-color: #fafafa;
    cursor: ns-resize
}

.sun-editor .se-resizing-bar.se-resizing-none {
    cursor: default
}

.sun-editor .se-resizing-back {
    position: absolute;
    display: none;
    cursor: default;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483647
}

.sun-editor .se-resizing-bar .se-navigation {
    flex: auto;
    position: relative;
    width: auto;
    height: auto;
    color: #666;
    margin: 0;
    padding: 0;
    font-size: 10px;
    line-height: 1.5;
    background: transparent
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper {
    flex: none;
    position: relative;
    display: block;
    width: auto;
    height: auto;
    margin: 0;
    padding: 0;
    color: #999;
    font-size: 13px;
    background: transparent
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper.se-blink {
    color: #b94a48;
    animation: blinker .2s linear infinite
}

.sun-editor .se-resizing-bar .se-char-counter-wrapper .se-char-label {
    margin-right: 4px
}

.sun-editor .se-dialog {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483647
}

.sun-editor .se-dialog button, .sun-editor .se-dialog input, .sun-editor .se-dialog label {
    font-size: 14px;
    line-height: 1.5;
    color: #111;
    margin: 0
}

.sun-editor .se-dialog .se-dialog-back {
    background-color: #222;
    opacity: .5
}

.sun-editor .se-dialog .se-dialog-back, .sun-editor .se-dialog .se-dialog-inner {
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
    position: relative;
    width: auto;
    max-width: 500px;
    margin: 1.75rem auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

@media screen and (max-width: 509px) {
    .sun-editor .se-dialog .se-dialog-inner .se-dialog-content {
        width: 100%
    }
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content label {
    display: inline-block;
    max-width: 100%;
    margin-bottom: 5px;
    font-weight: 700
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-content .se-btn-primary {
    display: inline-block;
    padding: 6px 12px;
    margin: 0 0 10px !important;
    font-size: 14px;
    font-weight: 400;
    line-height: 1.42857143;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation;
    border-radius: 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header {
    height: 50px;
    padding: 6px 15px;
    border-bottom: 1px solid #e5e5e5
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
    float: right;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
    -webkit-appearance: none;
    filter: alpha(opacity=100);
    opacity: 1
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
    float: left;
    font-size: 14px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 2.5
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-body {
    position: relative;
    padding: 15px 15px 5px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form {
    margin-bottom: 10px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer {
    margin-top: 10px;
    margin-bottom: 0
}

.sun-editor .se-dialog .se-dialog-inner input:disabled {
    background-color: #f3f3f3
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text {
    width: 100%
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-h, .sun-editor .se-dialog .se-dialog-inner .se-dialog-size-text .size-w {
    width: 70px;
    text-align: center
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-size-x {
    margin: 0 8px;
    width: 25px;
    text-align: center
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer {
    height: auto;
    min-height: 55px;
    padding: 10px 15px 0;
    text-align: right;
    border-top: 1px solid #e5e5e5
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div {
    float: left
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
    margin: 0 5px 0 0
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-radio {
    margin-left: 12px;
    margin-right: 6px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-check {
    margin-left: 12px;
    margin-right: 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer .se-dialog-btn-check {
    margin-left: 0;
    margin-right: 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form-footer label:first-child {
    margin-right: 16px;
    margin-left: 0
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files {
    position: relative;
    display: flex;
    align-items: center
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files > input {
    flex: auto
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button {
    flex: auto;
    opacity: .8;
    border: 1px solid #ccc
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button.se-file-remove > svg {
    width: 8px;
    height: 8px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button:hover {
    background-color: #f0f0f0;
    outline: 0 none
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-dialog-form-files .se-dialog-files-edge-button:active {
    background-color: #e9e9e9;
    box-shadow: inset 0 3px 5px #d6d6d6
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-select {
    display: inline-block;
    width: auto;
    height: 34px;
    font-size: 14px;
    text-align: center;
    line-height: 1.42857143
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-control {
    display: inline-block;
    width: 70px;
    height: 34px;
    font-size: 14px;
    text-align: center;
    line-height: 1.42857143
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form {
    display: block;
    width: 100%;
    height: 34px;
    font-size: 14px;
    line-height: 1.42857143;
    padding: 0 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-input-url {
    direction: ltr
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-input-url:disabled {
    text-decoration: line-through;
    color: #999
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-video-ratio {
    width: 70px;
    margin-left: 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form a {
    color: #004cff
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert {
    border: 1px solid #ccc
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:hover {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-btn-revert:active {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-dialog-tabs {
    width: 100%;
    height: 25px;
    border-bottom: 1px solid #e5e5e5
}

.sun-editor .se-dialog-tabs button {
    background-color: #e5e5e5;
    border-right: 1px solid #e5e5e5;
    float: left;
    outline: none;
    padding: 2px 13px;
    transition: .3s
}

.sun-editor .se-dialog-tabs button:hover {
    background-color: #fff
}

.sun-editor .se-dialog-tabs button.active {
    background-color: #fff;
    border-bottom: 0
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-form.se-math-exp {
    resize: vertical;
    height: 4rem;
    border: 1px solid #ccc;
    font-size: 13px;
    padding: 4px;
    direction: ltr
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-input-select.se-math-size {
    width: 6em;
    height: 28px;
    margin-left: 1em
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview {
    font-size: 13px
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > span {
    display: inline-block;
    box-shadow: 0 0 0 .1rem #c7deff
}

.sun-editor .se-dialog .se-dialog-inner .se-dialog-form .se-math-preview > span * {
    direction: ltr
}

.sun-editor .se-dialog .se-dialog-inner .se-link-preview {
    display: block;
    height: auto;
    max-height: 18px;
    font-size: 13px;
    font-weight: 400;
    color: #666;
    background-color: transparent;
    overflow: hidden;
    text-overflow: ellipsis;
    word-break: break-all;
    white-space: pre
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form {
    width: 100%;
    display: flex;
    margin-top: 4px
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form .se-svg.se-anchor-preview-icon {
    flex: unset;
    display: none;
    line-height: 1.5;
    color: #4592ff
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-preview-form .se-link-preview {
    flex: auto;
    margin: 0
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel {
    height: 34px
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-btn {
    width: 46px;
    color: #3f9dff
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-wrapper {
    display: flex;
    line-height: 1.5;
    padding-top: 6px
}

.sun-editor .se-dialog .se-dialog-inner .se-anchor-rel-preview {
    text-align: left
}

.sun-editor .se-controller .se-arrow.se-arrow-up {
    border-bottom-color: rgba(0, 0, 0, .25)
}

.sun-editor .se-controller {
    position: absolute;
    display: none;
    overflow: visible;
    z-index: 6;
    border: 1px solid rgba(0, 0, 0, .25);
    border-radius: 4px;
    text-align: start;
    text-decoration: none;
    text-shadow: none;
    text-transform: none;
    letter-spacing: normal;
    word-break: normal;
    word-spacing: normal;
    word-wrap: normal;
    white-space: normal;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    box-shadow: 0 5px 10px rgba(0, 0, 0, .2);
    line-break: auto
}

.sun-editor .se-controller .se-btn-group {
    position: relative;
    display: flex;
    vertical-align: middle;
    padding: 2px;
    top: 0;
    left: 0
}

.sun-editor .se-controller .se-btn-group .se-btn-group-sub {
    left: 50%;
    min-width: auto;
    width: -webkit-max-content;
    width: max-content;
    display: none
}

.sun-editor .se-controller .se-btn-group .se-btn-group-sub button {
    margin: 0;
    min-width: 72px
}

.sun-editor .se-controller .se-btn-group button {
    position: relative;
    min-height: 34px;
    height: auto;
    border: none;
    border-radius: 4px;
    margin: 1px;
    padding: 5px 10px;
    font-size: 12px;
    line-height: 1.5;
    display: inline-block;
    font-weight: 400;
    text-align: center;
    white-space: nowrap;
    vertical-align: middle;
    touch-action: manipulation
}

.sun-editor .se-controller .se-btn-group button:focus:enabled, .sun-editor .se-controller .se-btn-group button:hover:enabled {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-controller .se-btn-group button:active:enabled {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-controller .se-btn-group button span {
    display: block;
    padding: 0;
    margin: 0
}

.sun-editor .se-controller .se-btn-group button:enabled.active {
    color: #4592ff;
    outline: 0 none
}

.sun-editor .se-controller .se-btn-group button:enabled.active:focus, .sun-editor .se-controller .se-btn-group button:enabled.active:hover {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-controller .se-btn-group button:enabled.active:active {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    box-shadow: inset 0 3px 5px #c1c1c1
}

.sun-editor .se-controller .se-btn-group button:enabled.on {
    background-color: #e1e1e1;
    border-color: #d1d1d1;
    outline: 0 none
}

.sun-editor .se-controller .se-btn-group button:enabled.on:focus, .sun-editor .se-controller .se-btn-group button:enabled.on:hover {
    background-color: #d1d1d1;
    border-color: #c1c1c1;
    outline: 0 none
}

.sun-editor .se-controller .se-btn-group button:enabled.on:active {
    background-color: #c1c1c1;
    border-color: #b1b1b1;
    box-shadow: inset 0 3px 5px #b1b1b1
}

.sun-editor .se-controller .se-form-group input {
    min-width: 120px
}

.sun-editor .se-controller-resizing {
    margin-top: -50px !important;
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143
}

.sun-editor .se-controller-resizing .se-btn-group .se-btn-group-sub.se-resizing-align-list {
    width: 74px
}

.sun-editor .se-resizing-container {
    position: absolute;
    display: none;
    outline: 1px solid #3f9dff;
    background-color: transparent
}

.sun-editor .se-resizing-container .se-modal-resize {
    position: absolute;
    display: inline-block;
    background-color: #3f9dff;
    opacity: .3
}

.sun-editor .se-resizing-container .se-resize-dot {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%
}

.sun-editor .se-resizing-container .se-resize-dot > span {
    position: absolute;
    width: 7px;
    height: 7px;
    background-color: #3f9dff;
    border: 1px solid #4592ff
}

.sun-editor .se-resizing-container .se-resize-dot > span.tl {
    top: -5px;
    left: -5px;
    cursor: nw-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.tr {
    top: -5px;
    right: -5px;
    cursor: ne-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.bl {
    bottom: -5px;
    left: -5px;
    cursor: sw-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.br {
    right: -5px;
    bottom: -5px;
    cursor: se-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.lw {
    left: -7px;
    bottom: 50%;
    cursor: w-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.th {
    left: 50%;
    top: -7px;
    cursor: n-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.rw {
    right: -7px;
    bottom: 50%;
    cursor: e-resize
}

.sun-editor .se-resizing-container .se-resize-dot > span.bh {
    right: 50%;
    bottom: -7px;
    cursor: s-resize
}

.sun-editor .se-resizing-container .se-resize-display {
    position: absolute;
    right: 0;
    bottom: 0;
    padding: 5px;
    margin: 5px;
    font-size: 12px;
    color: #fff;
    background-color: #333;
    border-radius: 4px
}

.sun-editor .se-controller-table, .sun-editor .se-controller-table-cell {
    width: auto
}

.sun-editor .se-controller-link, .sun-editor .se-controller-table, .sun-editor .se-controller-table-cell {
    padding: 0;
    font-size: 14px;
    font-style: normal;
    font-weight: 400;
    line-height: 1.42857143
}

.sun-editor .se-controller-link:after, .sun-editor .se-controller-link:before {
    box-sizing: border-box
}

.sun-editor .se-controller-link .link-content {
    padding: 0;
    margin: 0
}

.sun-editor .se-controller-link .link-content a {
    display: inline-block;
    color: #4592ff;
    max-width: 200px;
    overflow: hidden;
    text-overflow: ellipsis;
    white-space: nowrap;
    vertical-align: middle;
    margin-left: 5px
}

.sun-editor .se-select-list {
    position: absolute;
    top: 0;
    left: 0;
    display: none;
    width: auto;
    max-width: 100%;
    background-color: #fff;
    padding: 0;
    margin: 0;
    border: 1px solid #bababa;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5);
    outline: 0 none
}

.sun-editor .se-select-list .se-select-item {
    line-height: 28px;
    min-height: 28px;
    font-size: 13px;
    padding: 0 5px;
    margin: 2px 0;
    cursor: pointer
}

.sun-editor .se-select-list.__se_select-menu-mouse-move .se-select-item:hover, .sun-editor .se-select-list:not(.__se_select-menu-mouse-move) .se-select-item.active {
    background-color: #e1e1e1
}

.sun-editor .se-dialog-form-files .se-select-list {
    width: 100%
}

.sun-editor .se-file-browser {
    position: absolute;
    display: none;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2147483647
}

.sun-editor .se-file-browser button, .sun-editor .se-file-browser input, .sun-editor .se-file-browser label {
    font-size: 14px;
    line-height: 1.5;
    color: #111;
    margin: 0
}

.sun-editor .se-file-browser .se-file-browser-back {
    background-color: #222;
    opacity: .5
}

.sun-editor .se-file-browser .se-file-browser-back, .sun-editor .se-file-browser .se-file-browser-inner {
    position: absolute;
    display: block;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0
}

.sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
    position: relative;
    width: 960px;
    max-width: 100%;
    margin: 20px auto;
    background-color: #fff;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    border: 1px solid rgba(0, 0, 0, .2);
    border-radius: 4px;
    outline: 0;
    box-shadow: 0 3px 9px rgba(0, 0, 0, .5)
}

.sun-editor .se-file-browser .se-file-browser-header {
    height: auto;
    min-height: 50px;
    padding: 6px 15px;
    border-bottom: 1px solid #e5e5e5
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-close {
    float: right;
    font-weight: 700;
    text-shadow: 0 1px 0 #fff;
    -webkit-appearance: none;
    filter: alpha(opacity=100);
    opacity: 1
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-close > svg {
    width: 12px;
    height: 12px
}

.sun-editor .se-file-browser .se-file-browser-header .se-file-browser-title {
    font-size: 16px;
    font-weight: 700;
    margin: 0;
    padding: 0;
    line-height: 2.2
}

.sun-editor .se-file-browser .se-file-browser-tags {
    display: block;
    width: 100%;
    padding: 0;
    text-align: left;
    margin: 0 -15px
}

.sun-editor .se-file-browser .se-file-browser-tags a {
    display: inline-block;
    background-color: #f5f5f5;
    padding: 6px 12px;
    margin: 8px 0 8px 8px;
    color: #333;
    text-decoration: none;
    border-radius: 32px;
    -moz-border-radius: 32px;
    -webkit-border-radius: 32px;
    -moz-background-clip: padding;
    -webkit-background-clip: padding-box;
    background-clip: padding-box;
    cursor: pointer
}

.sun-editor .se-file-browser .se-file-browser-tags a:hover {
    background-color: #e1e1e1
}

.sun-editor .se-file-browser .se-file-browser-tags a:active {
    background-color: #d1d1d1
}

.sun-editor .se-file-browser .se-file-browser-tags a.on {
    background-color: #ebf3fe;
    color: #4592ff
}

.sun-editor .se-file-browser .se-file-browser-tags a.on:hover {
    background-color: #d8e8fe
}

.sun-editor .se-file-browser .se-file-browser-tags a.on:active {
    background-color: #c7deff
}

.sun-editor .se-file-browser .se-file-browser-body {
    position: relative;
    height: auto;
    min-height: 350px;
    padding: 20px;
    overflow-y: auto
}

.sun-editor .se-file-browser .se-file-browser-body .se-file-browser-list {
    position: relative;
    width: 100%
}

@media screen and (max-width: 992px) {
    .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
        width: 748px
    }
}

@media screen and (max-width: 768px) {
    .sun-editor .se-file-browser .se-file-browser-inner .se-file-browser-content {
        width: 600px
    }
}

.sun-editor .se-file-browser .se-file-browser-list .se-file-item-column {
    position: relative;
    display: block;
    height: auto;
    float: left
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
    width: calc(25% - 20px);
    margin: 0 10px
}

@media screen and (max-width: 992px) {
    .sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
        width: calc(33% - 20px)
    }
}

@media screen and (max-width: 768px) {
    .sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-column {
        width: calc(50% - 20px)
    }
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img {
    position: relative;
    display: block;
    cursor: pointer;
    width: 100%;
    height: auto;
    border-radius: 4px;
    outline: 0;
    margin: 10px 0
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img:hover {
    opacity: .8;
    box-shadow: 0 0 0 .2rem #3288ff
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > img {
    position: relative;
    display: block;
    width: 100%;
    border-radius: 4px;
    outline: 0;
    height: auto
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > .se-file-img-name {
    position: absolute;
    z-index: 1;
    font-size: 13px;
    color: #fff;
    left: 0;
    bottom: 0;
    padding: 5px 10px;
    background-color: transparent;
    width: 100%;
    height: 30px;
    border-bottom-right-radius: 4px;
    border-bottom-left-radius: 4px
}

.sun-editor .se-file-browser .se-file-browser-list.se-image-list .se-file-item-img > .se-file-img-name.se-file-name-back {
    background-color: #333;
    opacity: .6
}

.sun-editor .se-notice {
    position: absolute;
    top: 0;
    display: none;
    z-index: 7;
    width: 100%;
    height: auto;
    word-break: break-all;
    font-size: 13px;
    color: #b94a48;
    background-color: #f2dede;
    padding: 15px;
    margin: 0;
    border: 1px solid #eed3d7;
    user-select: auto;
    -o-user-select: auto;
    -moz-user-select: auto;
    -khtml-user-select: auto;
    -webkit-user-select: auto;
    -ms-user-select: auto
}

.sun-editor .se-notice button {
    float: right;
    padding: 7px
}

.sun-editor .se-tooltip {
    position: relative;
    overflow: visible
}

.sun-editor .se-tooltip .se-tooltip-inner {
    visibility: hidden;
    position: absolute;
    display: block;
    width: auto;
    top: 120%;
    left: 50%;
    background: transparent;
    opacity: 0;
    z-index: 1;
    line-height: 1.5;
    transition: opacity .5s;
    margin: 0;
    padding: 0;
    bottom: auto;
    float: none;
    pointer-events: none;
    backface-visibility: hidden;
    -webkit-backface-visibility: hidden;
    -moz-backface-visibility: hidden
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text {
    position: relative;
    display: inline-block;
    width: auto;
    left: -50%;
    font-size: .9em;
    margin: 0;
    padding: 4px 6px;
    border-radius: 2px;
    background-color: #333;
    color: #fff;
    text-align: center;
    line-height: unset;
    white-space: nowrap;
    cursor: auto
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text:after {
    content: "";
    position: absolute;
    bottom: 100%;
    left: 50%;
    margin-left: -5px;
    border: 5px solid transparent;
    border-bottom-color: #333
}

.sun-editor .se-tooltip:hover .se-tooltip-inner {
    visibility: visible;
    opacity: 1
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
    display: block !important
}

.sun-editor .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut > .se-shortcut-key {
    display: inline;
    font-weight: 700
}

.sun-editor.se-rtl .se-btn-tray {
    direction: rtl
}

.sun-editor.se-rtl .se-btn-select svg {
    margin: auto 1px
}

.sun-editor.se-rtl .se-btn-select .txt {
    flex: auto;
    text-align: right;
    direction: rtl
}

.sun-editor.se-rtl .se-btn-list {
    text-align: right
}

.sun-editor.se-rtl .se-btn-list > .se-list-icon {
    margin: -1px 0 0 10px
}

.sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix) {
    float: right
}

.sun-editor.se-rtl .se-menu-list:not(.se-menu-dir-fix) li {
    float: right
}

.sun-editor.se-rtl .se-list-layer * {
    direction: rtl
}

.sun-editor.se-rtl .se-list-layer.se-list-format ul blockquote {
    padding: 0 7px 0 0;
    border-right-width: 5px;
    border-left-width: 0
}

.sun-editor.se-rtl .se-list-layer .se-selector-color .se-color-pallet li {
    float: right
}

.sun-editor.se-rtl .se-list-inner .se-list-checked li button > .se-svg {
    float: right;
    padding: 6px 0 0 6px
}

.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text, .sun-editor.se-rtl .se-wrapper .se-placeholder {
    direction: rtl
}

.sun-editor.se-rtl .se-tooltip .se-tooltip-inner .se-tooltip-text .se-shortcut {
    direction: ltr
}

.sun-editor.se-rtl .se-dialog * {
    direction: rtl
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-header .se-dialog-close {
    float: left
}

.sun-editor.se-rtl .se-dialog-tabs button, .sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-header .se-modal-title {
    float: right
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-size-text {
    padding-right: 34px
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer .se-btn-primary {
    float: left
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div {
    float: right
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-footer > div > label {
    margin: 0 0 0 5px
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-dialog-form-footer label:first-child {
    margin-left: 16px;
    margin-right: 0
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-preview {
    margin-left: 4px;
    text-align: right
}

.sun-editor.se-rtl .se-dialog .se-dialog-inner .se-anchor-rel-btn {
    float: right
}

.sun-editor.se-rtl .se-file-browser * {
    direction: rtl
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-tags {
    text-align: right
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-tags a {
    margin: 8px 8px 0
}

.sun-editor.se-rtl .se-file-browser .se-file-browser-header .se-file-browser-close {
    float: left
}

.sun-editor.se-rtl .se-controller .se-btn-group, .sun-editor.se-rtl .se-resizing-container .se-resize-display {
    direction: rtl
}

.sun-editor .se-btn-module-border.module-float-left {
    float: left
}

.sun-editor .se-btn-module-border.module-float-right {
    float: right
}

.sun-editor hr.__se__solid {
    border-style: solid none none
}

.sun-editor hr.__se__dotted {
    border-style: dotted none none
}

.sun-editor hr.__se__dashed {
    border-style: dashed none none
}

@keyframes blinker {
    50% {
        opacity: 0
    }
}

@keyframes spinner {
    to {
        transform: rotate(361deg)
    }
}

.sun-editor-editable {
    font-size: 13px;
    color: #333;
    line-height: 1.5;
    word-break: normal;
    word-wrap: break-word;
    padding: 14px;
    margin: 0
}

.sun-editor-editable * {
    box-sizing: border-box;
    font-size: inherit;
    color: inherit
}

.sun-editor-editable.se-rtl * {
    direction: rtl
}

.sun-editor-editable audio, .sun-editor-editable figcaption, .sun-editor-editable figure, .sun-editor-editable iframe, .sun-editor-editable img, .sun-editor-editable td, .sun-editor-editable th, .sun-editor-editable video {
    position: relative
}

.sun-editor-editable .__se__float-left {
    float: left
}

.sun-editor-editable .__se__float-right {
    float: right
}

.sun-editor-editable .__se__float-center {
    float: center
}

.sun-editor-editable .__se__float-none {
    float: none
}

.sun-editor-editable span {
    display: inline;
    vertical-align: baseline;
    margin: 0;
    padding: 0
}

.sun-editor-editable span.katex {
    display: inline-block
}

.sun-editor-editable span.katex * {
    direction: ltr
}

.sun-editor-editable a {
    color: #004cff;
    text-decoration: none
}

.sun-editor-editable span[style~="color:"] a {
    color: inherit
}

.sun-editor-editable a:focus, .sun-editor-editable a:hover {
    cursor: pointer;
    color: #0093ff;
    text-decoration: underline
}

.sun-editor-editable a.on {
    color: #0093ff;
    background-color: #e8f7ff
}

.sun-editor-editable pre {
    display: block;
    padding: 8px;
    margin: 0 0 10px;
    font-family: monospace;
    color: #666;
    line-height: 1.45;
    background-color: #f9f9f9;
    border: 1px solid #e1e1e1;
    border-radius: 2px;
    white-space: pre-wrap !important;
    word-wrap: break-word;
    overflow: visible
}

.sun-editor-editable ol {
    list-style-type: decimal
}

.sun-editor-editable ol, .sun-editor-editable ul {
    list-style-position: outside;
    display: block;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding-inline-start: 40px
}

.sun-editor-editable ul {
    list-style-type: disc
}

.sun-editor-editable li {
    display: list-item;
    text-align: -webkit-match-parent;
    margin-bottom: 5px
}

.sun-editor-editable ol ol, .sun-editor-editable ol ul, .sun-editor-editable ul ol, .sun-editor-editable ul ul {
    margin: 0
}

.sun-editor-editable ol ol, .sun-editor-editable ul ol {
    list-style-type: lower-alpha
}

.sun-editor-editable ol ol ol, .sun-editor-editable ul ol ol, .sun-editor-editable ul ul ol {
    list-style-type: upper-roman
}

.sun-editor-editable ol ul, .sun-editor-editable ul ul {
    list-style-type: circle
}

.sun-editor-editable ol ol ul, .sun-editor-editable ol ul ul, .sun-editor-editable ul ul ul {
    list-style-type: square
}

.sun-editor-editable sub, .sun-editor-editable sup {
    font-size: 75%;
    line-height: 0
}

.sun-editor-editable sub {
    vertical-align: sub
}

.sun-editor-editable sup {
    vertical-align: super
}

.sun-editor-editable p {
    display: block;
    margin: 0 0 10px
}

.sun-editor-editable div {
    display: block;
    margin: 0;
    padding: 0
}

.sun-editor-editable blockquote {
    display: block;

    font-size: inherit;
    color: #999;
    margin-block-start: 1em;
    margin-block-end: 1em;
    margin-inline-start: 0;
    margin-inline-end: 0;
    padding: 0 5px 0 20px;
    border: solid #b1b1b1;
    border-width: 0 0 0 5px
}

.sun-editor-editable blockquote blockquote {
    border-color: #c1c1c1
}

.sun-editor-editable blockquote blockquote blockquote {
    border-color: #d1d1d1
}

.sun-editor-editable blockquote blockquote blockquote blockquote {
    border-color: #e1e1e1
}

.sun-editor-editable.se-rtl blockquote {
    padding-left: 5px;
    padding-right: 20px;
    border-left-width: 0;
    border-right-width: 5px
}

.sun-editor-editable h1 {
    font-size: 2em;
    margin-block-start: .67em;
    margin-block-end: .67em
}

.sun-editor-editable h1, .sun-editor-editable h2 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700
}

.sun-editor-editable h2 {
    font-size: 1.5em;
    margin-block-start: .83em;
    margin-block-end: .83em
}

.sun-editor-editable h3 {
    font-size: 1.17em;
    margin-block-start: 1em;
    margin-block-end: 1em
}

.sun-editor-editable h3, .sun-editor-editable h4 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700
}

.sun-editor-editable h4 {
    font-size: 1em;
    margin-block-start: 1.33em;
    margin-block-end: 1.33em
}

.sun-editor-editable h5 {
    font-size: .83em;
    margin-block-start: 1.67em;
    margin-block-end: 1.67em
}

.sun-editor-editable h5, .sun-editor-editable h6 {
    display: block;
    margin-inline-start: 0;
    margin-inline-end: 0;
    font-weight: 700
}

.sun-editor-editable h6 {
    font-size: .67em;
    margin-block-start: 2.33em;
    margin-block-end: 2.33em
}

.sun-editor-editable hr {
    display: flex;
    border-width: 1px 0 0;
    border-color: #000;
    border-image: initial;
    height: 1px
}

.sun-editor-editable hr.__se__solid {
    border-style: solid none none
}

.sun-editor-editable hr.__se__dotted {
    border-style: dotted none none
}

.sun-editor-editable hr.__se__dashed {
    border-style: dashed none none
}

.sun-editor-editable hr.on {
    border-color: #4592ff;
    box-shadow: 0 0 0 .1rem #c7deff
}

.sun-editor-editable table {
    display: table;
    table-layout: auto !important;
    border: 1px solid #ccc;
    width: 100%;
    max-width: 100%;
    margin: 0 0 10px;
    background-color: transparent;
    border-spacing: 0;
    border-collapse: collapse
}

.sun-editor-editable.se-rtl table {
    margin: 0 0 10px auto
}

.sun-editor-editable table thead {
    border-bottom: 2px solid #333
}

.sun-editor-editable table tr {
    border: 1px solid #efefef
}

.sun-editor-editable table th {
    background-color: #f3f3f3
}

.sun-editor-editable table td, .sun-editor-editable table th {
    border: 1px solid #e1e1e1;
    padding: .4em;
    background-clip: padding-box
}

.sun-editor-editable table.se-table-size-auto {
    width: auto !important
}

.sun-editor-editable table.se-table-size-100 {
    width: 100% !important
}

.sun-editor-editable table.se-table-layout-auto {
    table-layout: auto !important
}

.sun-editor-editable table.se-table-layout-fixed {
    table-layout: fixed !important
}

.sun-editor-editable table td.se-table-selected-cell, .sun-editor-editable table th.se-table-selected-cell {
    outline: 1px double #4592ff
}

.sun-editor-editable.se-disabled * {
    user-select: none;
    -o-user-select: none;
    -moz-user-select: none;
    -khtml-user-select: none;
    -webkit-user-select: none;
    -ms-user-select: none
}

.sun-editor-editable .se-component {
    display: flex;
    padding: 1px;
    margin: 0 0 10px
}

.sun-editor-editable[contenteditable=true] .se-component {
    outline: 1px dashed #e1e1e1
}

.sun-editor-editable[contenteditable=true] .se-component.se-component-copy {
    box-shadow: 0 0 0 .2rem #3f9dff;
    transition: border-color .15s ease-in-out, box-shadow .15s ease-in-out
}

.sun-editor-editable audio, .sun-editor-editable iframe, .sun-editor-editable img, .sun-editor-editable video {
    display: block;
    margin: 0;
    padding: 0;
    width: auto;
    height: auto;
    max-width: 100%
}

.sun-editor-editable[contenteditable=true] figure:after {
    position: absolute;
    content: "";
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    cursor: default;
    display: block;
    background: transparent
}

.sun-editor-editable[contenteditable=true] figure a, .sun-editor-editable[contenteditable=true] figure iframe, .sun-editor-editable[contenteditable=true] figure img, .sun-editor-editable[contenteditable=true] figure video {
    z-index: 0
}

.sun-editor-editable[contenteditable=true] figure figcaption {
    display: block;
    z-index: 2
}

.sun-editor-editable[contenteditable=true] figure figcaption:focus {
    border-color: #80bdff;
    outline: 0;
    box-shadow: 0 0 0 .2rem #c7deff
}

.sun-editor-editable .se-image-container, .sun-editor-editable .se-video-container {
    width: auto;
    height: auto;
    max-width: 100%
}

.sun-editor-editable figure {
    display: block;
    outline: none;
    margin: 0;
    padding: 0
}

.sun-editor-editable figure figcaption {
    padding: 1em .5em;
    margin: 0;
    background-color: #f9f9f9;
    outline: none
}

.sun-editor-editable figure figcaption p {
    line-height: 2;
    margin: 0
}

.sun-editor-editable .se-image-container a img {
    padding: 1px;
    margin: 1px;
    outline: 1px solid #4592ff
}

.sun-editor-editable .se-video-container iframe, .sun-editor-editable .se-video-container video {
    outline: 1px solid #9e9e9e;
    position: absolute;
    top: 0;
    left: 0;
    border: 0;
    width: 100%;
    height: 100%
}

.sun-editor-editable .se-video-container figure {
    left: 0;
    width: 100%;
    max-width: 100%
}

.sun-editor-editable audio {
    width: 300px;
    height: 54px
}

.sun-editor-editable audio.active {
    outline: 2px solid #80bdff
}

.sun-editor-editable.se-show-block div, .sun-editor-editable.se-show-block h1, .sun-editor-editable.se-show-block h2, .sun-editor-editable.se-show-block h3, .sun-editor-editable.se-show-block h4, .sun-editor-editable.se-show-block h5, .sun-editor-editable.se-show-block h6, .sun-editor-editable.se-show-block li, .sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block p, .sun-editor-editable.se-show-block pre, .sun-editor-editable.se-show-block ul {
    border: 1px dashed #3f9dff !important;
    padding: 14px 8px 8px !important
}

.sun-editor-editable.se-show-block ol, .sun-editor-editable.se-show-block ul {
    border: 1px dashed #d539ff !important
}

.sun-editor-editable.se-show-block pre {
    border: 1px dashed #27c022 !important
}

.se-show-block p {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAA0AAAAPAQMAAAAF7dc0AAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAaSURBVAjXY/j/gwGCPvxg+F4BQiAGDP1HQQByxxw0gqOzIwAAAABJRU5ErkJggg==") no-repeat
}

.se-show-block div {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABUAAAAPAQMAAAAxlBYoAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j//wcDDH+8XsHwDYi/hwNx1A8w/nYLKH4XoQYJAwCXnSgcl2MOPgAAAABJRU5ErkJggg==") no-repeat
}

.se-show-block h1 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAfSURBVAjXY/j/v4EBhr+9B+LzEPrDeygfhI8j1CBhAEhmJGY4Rf6uAAAAAElFTkSuQmCC") no-repeat
}

.se-show-block h2 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAmSURBVAjXY/j/v4EBhr+dB+LtQPy9geEDEH97D8T3gbgdoQYJAwA51iPuD2haEAAAAABJRU5ErkJggg==") no-repeat
}

.se-show-block h3 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQPy9geHDeQgN5p9HqEHCADeWI+69VG2MAAAAAElFTkSuQmCC") no-repeat
}

.se-show-block h4 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABIAAAAPAQMAAADTSA1RAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j//wADDH97DsTXIfjDdiDdDMTfIRhZHRQDAKJOJ6L+K3y7AAAAAElFTkSuQmCC") no-repeat
}

.se-show-block h5 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAlSURBVAjXY/j/v4EBhr+1A/F+IO5vYPiwHUh/B2IQfR6hBgkDABlWIy5uM+9GAAAAAElFTkSuQmCC") no-repeat
}

.se-show-block h6 {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABEAAAAPAQMAAAA4f7ZSAAAABlBMVEWAgID////n1o2sAAAAAnRSTlP/AOW3MEoAAAAiSURBVAjXY/j/v4EBhr+dB+LtQLy/geFDP5S9HSKOrA6KAR9GIza1ptJnAAAAAElFTkSuQmCC") no-repeat
}

.se-show-block li {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAPCAYAAADkmO9VAAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA7SURBVDhPYxgFcNDQ0PAfykQBIHEYhgoRB/BpwCfHBKWpBkaggYxQGgOgBzyQD1aLLA4TGwWDGjAwAACR3RcEU9Ui+wAAAABJRU5ErkJggg==") no-repeat
}

.se-show-block ol {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAABHSURBVDhPYxgFcNDQ0PAfhKFcFIBLHCdA1oBNM0kGEmMAPgOZoDTVANUNxAqQvURMECADRiiNAWCagDSGGhyW4DRrMAEGBgAu0SX6WpGgjAAAAABJRU5ErkJggg==") no-repeat
}

.se-show-block ul {
    background: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAABQAAAAMCAYAAABiDJ37AAAAAXNSR0IArs4c6QAAAARnQU1BAACxjwv8YQUAAAAJcEhZcwAADsMAAA7DAcdvqGQAAAA1SURBVDhPYxgFDA0NDf+hTBSALI5LDQgwQWmqgVEDKQcsUBoF4ItFGEBXA+QzQpmDGjAwAAA8DQ4Lni6gdAAAAABJRU5ErkJggg==") no-repeat
}

.sun-editor-editable .__se__p-bordered, .sun-editor .__se__p-bordered {
    border-top: 1px solid #b1b1b1;
    border-bottom: 1px solid #b1b1b1;
    padding: 4px 0
}

.sun-editor-editable .__se__p-spaced, .sun-editor .__se__p-spaced {
    letter-spacing: 1px
}

.sun-editor-editable .__se__p-neon, .sun-editor .__se__p-neon {
    font-weight: 200;
    font-style: italic;
    background: #000;
    color: #fff;
    padding: 6px 4px;
    border: 2px solid #fff;
    border-radius: 6px;
    text-transform: uppercase;
    animation: neonFlicker 1.5s infinite alternate
}

@keyframes neonFlicker {
    0%, 19%, 21%, 23%, 25%, 54%, 56%, to {
        text-shadow: -.2rem -.2rem 1rem #fff, .2rem .2rem 1rem #fff, 0 0 2px #f40, 0 0 4px #f40, 0 0 6px #f40, 0 0 8px #f40, 0 0 10px #f40;
        box-shadow: 0 0 .5px #fff, inset 0 0 .5px #fff, 0 0 2px #08f, inset 0 0 2px #08f, 0 0 4px #08f, inset 0 0 4px #08f
    }
    20%, 24%, 55% {
        text-shadow: none;
        box-shadow: none
    }
}

.sun-editor-editable .__se__t-shadow, .sun-editor .__se__t-shadow {
    text-shadow: -.2rem -.2rem 1rem #fff, .2rem .2rem 1rem #fff, 0 0 .2rem #999, 0 0 .4rem #888, 0 0 .6rem #777, 0 0 .8rem #666, 0 0 1rem #555
}

.sun-editor-editable .__se__t-code, .sun-editor .__se__t-code {
    font-family: monospace;
    color: #666;
    background-color: rgba(27, 31, 35, .05);
    border-radius: 6px;
    padding: .2em .4em
}

.mobile-header_hidden {
    padding-top: 10px;
}

.mobile-header_row-2 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    text-overflow: clip;
}

.mobile-header_whatsapp {
    margin-right: 5px;
    flex-shrink: 0;
}

.header_tel {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    display: block;
}

.header_tels{
    display: block;
}

.header_tel {
    color: #000;
    font-size: 16px;
    line-height: 1.5;
}

.header_time {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    display: block;
    text-align: right;
    line-height: 1.3;
    padding-top: 5px;
}

.header_time {
    color: #000;
    font-size: 13px;
    line-height: 1.5;
}

.mobile-header_button {
    margin-left: auto;
    margin-right: 10px;
}

input[type=submit], .main-slider_button, .nav_button, .services_button, .calculator_button, .call-block_button, .portfolio_button {
    height: auto;
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 1;
}

.nav_button {
    display: block;
    font-size: 13px;
    padding: 7px 10px;
}

.nav_button {
    background-color: #fab30d;
    padding: 10px 24px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    border-radius: 4px;
    transition: .3s;
}

.header_nav-list li a {
    padding: 0 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    position: relative;
    height: 17px;
    display: block;
}

.header_nav-list li a {
    color: #000;
    font-size: 16px;
    padding: 5px 0;
    display: block;
    height: 29px;
}

.mobile-header_hidden ul {
    list-style: none;
}
.root {
    min-height: 100vh;
}

.navbar-light .navbar-nav .nav-link.active {
    color: rgba(186, 186, 186, 1) !important;
    text-underline: #adb8c0;
}

.navbar-light .navbar-nav .nav-link {
    color: rgba(0, 0, 0, 1);
}

.padding {
    height: 55px;
}

.card-deck {
    padding-bottom: 15px;
}

.close-elem-ico {
    position: fixed;
    top: 40px;
    right: 40px;
    z-index: 101;
    cursor: pointer;
    width: 18px;
    height: 18px;
    display: block;
}

.box {
    position: relative;
    overflow: hidden;
    width: 100%;
}

.box img {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    object-fit: cover;
}

.my-modal {
    position: fixed;
    padding: 0 15px;
    top: 70px;
    left: 0;
    width: 100%;
    height: 30%;
    z-index: 2000;
    overflow: hidden;
    background: white;
    transition: 0.8s; /*Скорость перехода состояния элемента*/
    animation: height, top 0.5s 1; /* Указываем название анимации, её время и количество повторов*/
    animation-fill-mode: forwards; /* Чтобы элемент оставался в конечном состоянии анимации */
    animation-delay: 0s; /* Задержка перед началом */
}

.modal-order {
    position: fixed;
    padding: 0 15px;
    top: 50px;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 3000;
    text-align: center;
    overflow: hidden;
    background: rgba(199, 199, 199, 0.6);
}

.modal-order-form {
    width: 80%;
    height: 50%;
    background: #2a2a2a;
    border: 1px solid #f3f37d;
    top: 5%;
    position: relative;
    left: 10%;
}

.shot-menu .my-modal {
    height: 0;
}
.long-menu .my-modal {
    height: 33%;
    background-color: #fab30d;
}

.modal-project-image {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2000;
    background: rgba(0, 0, 0, 0.81);
}

.modal-open {
    overflow: hidden;
}

.hint {
    font-size: 10px;
    font-style: italic;
    color: darkgrey;
    margin-top: -8px;
    padding-bottom: 10px;
}

.sticky{
    position: -webkit-sticky;
    position: sticky;
    top: 0;
}

.fixed-top {
    width: 100vw;
    /*transition: 2s; !*Скорость перехода состояния элемента*!*/
    /*animation: show 1s 1; !* Указываем название анимации, её время и количество повторов*!*/
    /*animation-fill-mode: forwards; !* Чтобы элемент оставался в конечном состоянии анимации *!*/
    /*animation-delay: 0s; !* Задержка перед началом *!*/
}

@keyframes show {
    0% {
        margin-top: -50px;
    }
    100% {
        margin-top: 0;
    }
}

@keyframes hinge {
    100% {
        margin-top: 0;
    }
    0% {
        margin-top: -50px;
    }

}

.dlt-margin {
    margin-left: 290px;
    margin-top: -67px;
    padding-bottom: 35px;
}

.pointer {
    cursor: pointer;
}

.menu {
    background: #2a2a2a;
    letter-spacing: .2em;
    color: rgb(252, 252, 252);
    line-height: 14px;
    font-size: 16px;
    max-height: 75px;
}

.description {
    background: white;
    letter-spacing: .1em;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 5px;
    color: rgba(0, 0, 0, 1);
    font-size: 11px;
    text-indent: 1.5em; /* Отступ первой строки */
    text-align: justify; /* Выравнивание по ширине */
    line-height: 16px;
}

.menu a {
    color: rgb(252, 249, 249);
}

.menu a:hover, .menu .active {
    color: rgb(245, 162, 9);
    -webkit-text-decoration: underline rgb(245, 162, 9);
            text-decoration: underline rgb(245, 162, 9);

}

.my-modal .menu {
    transition: 1s; /*Скорость перехода состояния элемента*/
}

.invisible-block {
    visibility: hidden;
}

.visible-block:hover ~ .invisible-block {
    visibility: visible;
}

.invisible-block:hover {
    visibility: visible;
}

.rdw-editor-main {
    height: 100%;
    overflow: auto;
    box-sizing: border-box;
}

.demo-editor {
    height: 225px !important;
    border: 1px solid #F1F1F1 !important;
    padding: 5px !important;
    border-radius: 2px !important;
}

.prices p {
    margin-bottom: 0 !important;
}

.ordering .container {
    max-width: 590px !important;
}

.open-label {
    padding-right: 5px;
    font-style: italic;
}

.open-label:hover {
    color: #f3f37d;
    padding-right: 5px;
    -webkit-text-decoration: #f3f37d underline;
            text-decoration: #f3f37d underline;
    cursor: pointer;

}

span.arr-btn:hover {
    border-bottom: 5px solid #f3f37d;
}

span.arr-top:hover {
    border-top: 5px solid #f3f37d;
}

span.arr-btn {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-bottom: 5px solid #ffffff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

span.arr-top {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-top: 5px solid #ffffff;
    border-right: 5px solid transparent;
    border-left: 5px solid transparent;
}

.fixed-menu {
    display: block;
    background-color: #454d55;
    background: #454d55;
}

.fixed-menu .menu-top {
    display: block;
    line-height: 60px;
}

.only-phone {
    display: none;
}

.mobile-menu{
    display: table-cell;
    vertical-align: middle;
    text-align: right;
    padding-right: 20px;
}

.menu-padding{
    padding-top: 70px;
}

/*Для большого экрана*/
@media only screen and (min-width: 951px) {

    .mobile-menu{
        display: none;
    }

    .mobile-price {
        display: block;
    }

    .background-fon {
        background: url("https://interiormydreams.ru/static/fon/fon.jfif");
    }

    .menu-top {
        display: block;
    }

    .size-logo {

    }

    .size-logo img {
       height: 75px;
        padding-left: 50px;
        padding-top: 5px;
        padding-bottom: 5px;
    }

    .cell-div-big {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 77%;
    }

    .menu-left {
        display: none;
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 31vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 31vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }

    .no-desctop {
        display: none !important;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .news img {
        width: 100vw !important;
        height: auto !important;
    }

    .mobile-menu{
        display: none;
    }

    .services_top {
        border: none !important;
        padding: 0  !important;
    }

    .mobile-price {
        display: none;
    }

    .services_row {
        display: block !important;
    }

    .services_col {
        width: 100% !important;
    }

    .only-desctop {
        display: none !important    ;
    }

    .menu-top {
        display: block;
    }

    .size-logo img {
        width: 65%;
    }

    .no-tablet {
        display: none !important;
    }

    .sun-editor-editable .no-tablet div {
        display: none !important;
    }

    .cell-div-big {
        display: table-cell;
        vertical-align: middle;
        text-align: center;
        width: 80%;
    }

    .menu-left {
        display: none;
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 62vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 62vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {

    .news img {
        width: 100% !important;
        height: auto !important;
    }

    .services_top {
        border: none !important;
        padding: 0  !important;
    }

    .mobile-price {
        display: none;
    }

    .services_row {
        display: block !important;
    }

    .services_col {
        width: 100% !important;
    }

    .only-desctop {
        display: none !important;
    }
    .menu-top {
        display: none;
    }

    .only-phone {
        display: block;
    }

    .size-logo img {
        height: 70px;
        padding: 10px;
    }

    .no-phone {
        display: none !important;
    }

    .sun-editor-editable .no-phone div {
        display: none !important;
    }

    .cell-div-big {
        display: none !important;
    }

    .menu-left {
        display: block;
    }

    .project-img-with-description-all {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .prices {
        margin-left: 10px;
        margin-bottom: 10px;
        width: 93vw;
        float: left;
    }

    .prices-block {
        background: rgb(247, 247, 247);
        width: 93vw;
        height: 250px;
        text-align: center;
        letter-spacing: 0;
        line-height: 1.2;
        font-size: 13px;
    }
}

/*слайдер просмотра изображеий*/

.project-image-modal {
    text-align: center;
    background: white;
    padding: 3px 0;
    width: auto;
    will-change: transform;
}

.project-image-modal-h {
    height: 100vh;
}

.project-image-modal-w {
    height: auto;
    width: 100vw;
    margin: auto;
}

.box2 img {
    max-width: 100%;
    max-height: 100%;
    height: 100%;
    will-change: transform;
}

.box3 img {
    max-width: 100%;
    max-height: 100%;
    width: 100%;
    will-change: transform;
    display: inline-block;
}

.box3 {
    height: 100%;
    background: #000000;
}

/*end*/

@media (min-width: 1200px) {
    .order-field {
        width: 550px;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 550px;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

@media (min-width: 992px) and (max-width: 1199px) {

    .order-field {
        width: 490px;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 490px;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

@media (max-width: 991px) {

    .order-field {
        width: 95vw;
        padding-bottom: 8px !important;
        padding-right: 5px !important;
    }

    .order-area {
        width: 95vw;
        height: 183px;
        margin-bottom: -38px;
        resize: none
    }
}

.table-container {
    width: 100%;
    margin: 10px auto;
    /* display: table; */
    border-collapse: collapse;
}

.form-row {
    /* display: table-row; */
    display: table;
    width: 100%;
    float: left;
    border-collapse: collapse;
}

.cell {
    display: table-cell;
    padding: 10px 20px;
    vertical-align: top;
    border-collapse: collapse;
    text-align: justify;
    text-indent: 1.5em;
}

.cell-info {
    display: table-cell;
    padding: 10px 20px;
    vertical-align: top;
    border-collapse: collapse;
    text-align: justify;
    width: 35%;

}

.color-white {
    color: white !important;
}

.size-18 {
    font-size: 2vmin;
    text-align: justify;
}

.border-b {
    border-bottom: 1px solid #4b4b4b;
}

.portfolio-link {

}

@font-face {
    font-family: 'Playfair';
    src: url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.eot'); /* IE 9 Compatibility Mode */
    src: url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.eot?#iefix') format('embedded-opentype'), /* IE < 9 */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.woff2') format('woff2'), /* Super Modern Browsers */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.woff') format('woff'), /* Firefox >= 3.6, any other modern browser */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.ttf') format('truetype'), /* Safari, Android, iOS */
    url('https://interiormydreams.ru/static/fonts/Playfair/playfair_display_regular.svg#playfair_display_regular') format('svg'); /* Chrome < 4, Legacy iOS */
}

.table-div {
    display: table !important;
}

.row-div {
    display: table-row !important;
}

.cell-div-10 {
    display: table-cell;
    vertical-align: middle;
    text-align: end;
}

.padding-10 {
    margin-bottom: 10px !important;;
}

.cell-div {
    display: table-cell;
    vertical-align: middle;
    text-align: center;
}

.cell-div-right {
    display: table-cell !important;
    vertical-align: middle;
    text-align: right;
    width: 17%;
    padding: 1px 1px 1px 0px;
}

.cell-div-100 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.h-15 {
    line-height: 20px;
    font-size: 1.8vmin !important;
}

.border-bt {
    border-bottom: 1px solid white;
}

.vertical-top {
    vertical-align: top;
}

.vertical-bottom {
    vertical-align: bottom;
    display: table-cell;
    border-bottom: 1px solid white;
}

.nav-link {
    display: inline-block;
}


.services_row {
    display: flex;
    margin: 0 -10px;
    margin-bottom: 40px;
}

.services_col {
    /*width: 25%;*/
    width: 32%;
    padding: 0 10px;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.services_item {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    flex-direction: column;
}

.services_top {
    border: 1px solid #dddede;
    padding: 25px 10px 0;
    flex-grow: 1;
    display: flex;
    flex-direction: column;
}

.services_heading {
    font-size: 20px;
    text-align: center;
    height: 50px;
    font-weight: 700;
}

.services_bottom {
    font-size: 18px;
    color: #000000;
    background: rgb(245, 162, 9);
    text-align: center;
    padding: 9px 0;
}

.services_bottom span {
    font-size: 26px;
    font-weight: 500;
}

.services_list ul {
    list-style: none;
    list-style-type: none !important;
    padding-inline-start: 15px !important;
}

.services_list li {
    padding: 3px 0;
    border-bottom: 1px solid #dddede;
    padding-left: 25px;
    background-image: url("https://interiormydreams.ru/static/media/li.png");
    background-position: left 0px center;
    background-repeat: no-repeat;
}

#priceContainer {
    max-width: 1700px
}

.open-btn {
    transform: rotate(-90deg);
    width: 5%
}
.close-btn {
    width: 5%
}

.tag-list {
    list-style: none;
    height: calc(100vh * 0.55);
    display: flex !important;
    flex-direction: column;
    flex-wrap: wrap
}
.tag-list li {
    page-break-inside: avoid;
    page-break-inside: avoid;
    break-inside: avoid;
}

.tag-block{
    height: calc(100vh * 0.55);
}

.modal-tag{
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: 2001;
    background: white;
}

#news_container .se-container {
    width: 98.2% !important;
}

#news_container .sun-editor {
    border: 0px solid #dadada;
}

.text-center{
    text-align: center;
}

.text-white{
    color: white;
}

.clear{clear:both;}


.anim-from-left-to-right2 {
    animation-name: fromLTR2;
    animation-duration: 1s;
}




.anim-from-right-to-left2  {
    animation-name: fromRTL2;
    animation-duration: 1s;

}


div, span, a, h1, h2, h3, h4, input, textarea, button {
    font-family: Playfair !important;
}

.arrow-next{
    color: white;
    fill: #fff;
    animation: shiftByY 1s alternate  ease-in-out infinite;
}

html {
    scroll-behavior: smooth;
}

.element-animation {
    margin: 2rem 0;
    font-size: 3rem;
    opacity: 0;
}

.element-animation.element-show {
    animation-name: bounceInUp;
    animation-duration: 1s;
    opacity: 1;
}

.test-img-blok {
    width: 100%;
    height: calc(100vh);
    border-radius: 31px;
    text-align:center;
    vertical-align: middle;
}

.slider-image {
    width: 100%;
    height:100%;
    object-fit:cover;
}

.first {
    animation-name: bounceInUp1;
    animation-duration: 1.5s;
    /*box-shadow: 10px 10px 10px rgba(158, 162, 156, 0.85);*/
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.second {
    animation-name: bounceInUp2;
    animation-duration: 2s;
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.third{
    animation-name: bounceInUp3;
    animation-duration: 2.5s;
    box-shadow:
            inset 0 -3em 3em rgba(0,0,0,0.1),
            0 0  0 1px rgb(255,255,255),
            0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.hide {
    opacity: 0;
}

.show {
    opacity: 1;
}

.logo-position {
    position: absolute;
    top: 22vh;
    left: 35%;
    z-index: 100;
    border-radius: 50px;
}

.logo-position img {
    background: radial-gradient(black, rgba(0, 0, 0, 0.49) 50%, rgba(0, 0, 0, 0) 60%, rgba(0, 0, 0, 0));
    border-radius: 50%;
}

.anhor-link-a:hover .anhor-link{
    width: 190px;
}
.anhor-link-a:hover .block-rt {
    right: 190px;
}

.anhor-link {
    position: absolute;
    right: 0;
    padding: 3px;
    display: block;
    width: 170px;
    margin-left: auto;
    z-index: 1000;
    color: white;
    cursor: pointer;
}

.block-rt {
    content: '';
    position: absolute; /* Абсолютное позиционирование */
    right: 170px;
    border: 15px solid transparent; /* Прозрачные границы */
     /* Добавляем треугольник */
    z-index: 1000;
}
.block-rt::after {
    border: 20px solid transparent; border-right: 20px solid green;
}


/*Для большого экрана*/
@media only screen and (min-width: 951px) {


    .test-blok {
        width: 23%;
        height: 20vw;
        border-radius: 31px;
        padding: 25px;
        margin: 10px;
    }
    .test-blok2 {
        width: 10%;
        height: 10vw;

        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .test-blok {
        width: 45%;
        height: 414px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
    .test-blok2 {
        width: 30%;
        height: 214px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {


    .test-blok {
        width: 90%;
        height: 414px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
    .test-blok2 {
        width: 90%;
        height: 214px;
        border: 1px solid rgba(158, 162, 156, 0.85);
        border-radius: 31px;
        padding: 10px;
        margin: 10px;
    }
}


@keyframes bounceInUp {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp1 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 3000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp2 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 6000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
@keyframes bounceInUp3 {
    60%, 75%, 90%, from, to {
        animation-timing-function: cubic-bezier(.215, .61, .355, 1)
    }

    from {
        opacity: 0;
        transform: translate3d(0, 9000px, 0)
    }

    60% {
        opacity: 1;
        transform: translate3d(0, -20px, 0)
    }

    75% {
        transform: translate3d(0, 10px, 0)
    }

    90% {
        transform: translate3d(0, -5px, 0)
    }

    to {
        transform: translate3d(0, 0, 0)
    }
}
.sociallinks {
    display: table-cell;
    vertical-align: middle;
    padding: 0 5px;
}
.project-title {
    position: absolute;
    color: white;
    padding: 20px;
    opacity: 1 !important;
    text-align: left;
    font-size: 25px;
    vertical-align: bottom;
    display: table-cell;
    bottom: 20%;
}

a.project-box-hover {
    float: left;
    text-decoration: none;
    position: relative;
}

a.project-box-hover:hover .project-img-fon {
    opacity: 0.8;
}

a.project-box-hover .project-img-fon {
    background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) .24%, rgb(0, 0, 0) 100%) !important;
    background-color: rgba(0, 0, 0, 0.8);
    transition: opacity 0.8s ease;
    opacity: 0.0;
}

.project-box-hover .info {
    opacity: 0;
}

.project-box-hover:hover .info {
    opacity: 1;
}

.project-box-hover .info .line,
.project-box-hover .info .read-more {
    opacity: 0;
}

.project-box-hover:hover .info .line,
.project-box-hover:hover .info .read-more {
    opacity: 1 !important;
}

.project-box-hover:hover .info .line {
    width: 80%;
}

.line {
    position: absolute;
    bottom: 20%;
    width: 0;
    transition: width 0.2s;
    height: 3px;
    border-top-right-radius: 5px;
    border-bottom-right-radius: 5px;
    background-color: rgb(245, 162, 9);
    opacity: 1 !important;
}

.read-more {
    position: absolute;
    bottom: 15%;
    width: 80%;
    height: 3px;
    color: white;
    border: none !important;
    padding-left: 20px;
    opacity: 1 !important;
}

span.arr {
    vertical-align: middle;
    margin: 0 6px;
    display: inline-block;
    width: 0;
    height: 0;
    border-left: 5px solid #ffffff;
    border-top: 5px solid transparent;
    border-bottom: 5px solid transparent;
}

/*Для большого экрана*/
@media only screen and (min-width: 951px) {
    .project-box {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 7px);
        height: calc(100vw * 0.98 / 3 - 12px);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-1 * 100vw * 0.98 / 3 + 7px);
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 7px);
        height: calc(100vw * 0.98 / 3 - 10px);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.95 / 3);
    }

    .project-box-hover .info {
        opacity: 0;
    }

    .project-box-hover:hover .info {
        margin-top: -200px;
        opacity: 1;
    }

    .project-box-hover .info .line,
    .project-box-hover .info .read-more {
        opacity: 0;
    }

    .project-box-hover:hover .info .line,
    .project-box-hover:hover .info .read-more {
        opacity: 1 !important;
    }

    .project-box-hover:hover .info .line {
        width: 80%;
    }

    .line {
        position: absolute;
        bottom: 20%;
        width: 0;
        transition: width 0.2s;
        height: 3px;
        border-top-right-radius: 5px;
        border-bottom-right-radius: 5px;
        background-color: rgb(245, 162, 9);
        opacity: 1 !important;
    }

    .read-more {
        position: absolute;
        bottom: 15%;
        width: 80%;
        height: 3px;
        color: white;
        border: none !important;
        padding-left: 20px;
        opacity: 1 !important;

    }

    .project-title {
        position: absolute;
        color: white;
        padding: 20px;
        opacity: 1 !important;
        text-align: left;
        font-size: 25px;
        vertical-align: bottom;
        display: table-cell;
        bottom: 20%;
    }

    .project-img {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.95 / 3);
    }


    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 3 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .broadcrambs,
    .category-sub-menu-container > .next,
    .category-sub-menu-container > .prev{
        display: none;
    }
}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {
    .project-box {
        margin-left: 5px;
        margin-right: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 7px);
        height: calc(100vw * 0.95 / 2 + 13px);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-1 * 100vw * 0.98 / 2 + 7px);
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 7px);
        height: calc(100vw * 0.98 / 2 - 10px);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.98 / 2);
    }

    .project-img {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.98 / 2);
        float: left
    }

    .project-img-with-description-all {
        margin-left: 5px;
        margin-bottom: 5px;
        width: calc(100vw * 0.98 / 2 - 5px);
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .project-title {
        font-size: 20px;
    }

    .broadcrambs,
    .category-sub-menu-container > .next,
    .category-sub-menu-container > .prev{
        display: none;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {
    .project-box {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(95vw);
        float: left;
    }

    a.project-box-hover {
        margin-left: calc(-96vw);
        margin-bottom: 5px;
        width: calc(96vw);
        height: calc(95vw);
    }

    a.project-box-hover .project-img-fon {
        margin-bottom: 5px;
        width: calc(96vw);
        height: calc(95vw);
    }

    .project-img {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: 95vw;
        float: left
    }

    .project-img-with-description-all {
        margin-left: 1vw;
        margin-right: 1vw;
        margin-bottom: 5px;
        width: 96vw;
        height: calc(100vw * 0.40 / 3);
        float: left
    }

    .project-title {
        font-size: 15px;
    }

    a.project-box-hover-phone .project-img-fon {
        background: linear-gradient(180deg, rgba(0, 0, 0, 0.12) .24%, rgb(0, 0, 0) 100%) !important;
        background-color: rgba(0, 0, 0, 0.8);
        transition: opacity 0.8s ease;
        opacity: 0.8;
    }

    .project-box-hover-phone .info {
        opacity: 1;
    }

    .project-box-hover-phone .info .line,
    .project-box-hover-phone .info .read-more {
        opacity: 1 !important;
    }

    .project-box-hover-phone .info .line {
        width: 80%;
    }

    .category-sub-menu-container {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
    }

    .category-sub-menu-item {
        display: flex;
        justify-content: center;
        scroll-snap-align: start;
        padding-left: 15px;
    }

    .category-sub-menu-container > .prev svg {
        rotate: 90deg;
        position: absolute;
        left: -5px;
        margin: 10px -15px 0 -5px;
        background-color: white;
        padding: 5px;
    }

    .category-sub-menu-container > .next svg {
        rotate: 270deg;
        position: absolute;
        right: -10px;
        margin: 9px 0 0 0;
        background-color: white;
        padding: 5px;
    }
}

.broadcrambs {
    /*text-transform: uppercase;*/
    padding: 10px;
    font-size: 20px;


}
.broadcrambs a {
    color: rgb(0, 0, 0);
}

.inline-block {
    display: inline-block;
}
.admin-link {
    padding: 20px;
}

.categories-list {
    width: 20%;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-right: 10px;
}

.categories-setting {
    width: 75%;
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.68);
}

.error {
    color: red;
    font-style: italic;
}

.sub-menu {
    text-align: center;
}

.sub-menu a {
    color: black;
    cursor: pointer;
    text-transform: uppercase;
    font-weight: bold;
    font-size: 14px;
}

.sub-menu a:hover {
    color: #fab30d;
    text-transform: uppercase;
    -webkit-text-decoration: #fab30d underline;
            text-decoration: #fab30d underline;

}




.input {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #eb9c34;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 10px 0;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
}

.privacy-policy a {
    color: white;

}
.privacy-policy a:hover {
    color: rgb(245, 162, 9);
}

.personal-data-style a {
    color: white;
    -webkit-text-decoration: white underline;
            text-decoration: white underline;
}

.personal-data-style a:hover {
    color: rgb(245, 162, 9);
    text-decoration: none;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .footer {
        background-color: #2a2a2a;
        text-align: center;
        align-content: center;
        color: white;
        padding-top: 20px;
        min-height: 91vh;
    }

    .f-inline {
        display: inline-block;
        width: 50%;
    }

    .f-contact-info {
        font-size: 30px;
        text-align: left;
        padding-bottom: 15px;
        padding-left: 300px;
    }

    .privacy-policy {
        font-size: 18px;
        padding-top: 125px;
    }

    .f-form {
        width: 70%;
    }

    .footer-info {
        position: relative;
        font-size: 20px;
        width: 40%;
        left: 30%;
        padding-top: 20px;
        padding-bottom: 40px;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .footer {
        background-color: #2a2a2a;
        text-align: center;
        align-content: center;
        color: white;
        padding-top: 50px;
        min-height: calc(105vh);
    }


    .f-contact-info {
        font-size: 25px;
        text-align: center;
        padding-bottom: 10px;
    }

    .privacy-policy {
        font-size: 18px;
        padding-top: 0px;
    }

    .f-form {
        width: 100%;
        padding: 0 10px 0 10px;
    }

    .footer-info {
        font-size: 15px;
        padding: 15px 10px 15px 10px;
    }

    .input {
        margin: 5px 0;
        height: 45px;
    }
}



.input-textarea {
    height: 120px;
}



.f-top{
    vertical-align: top;
}

.ft-btn{
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 3s ease-in-out alternate;
    -webkit-animation: leaves 3s ease-in-out alternate;
}

/*.ft-btn{*/
/*    top: calc(25vh);*/
/*}*/
.cm-btn_effect {
    background: linear-gradient(90deg, rgba(255, 255, 255, .1), rgba(255, 255, 255, .4));
    width: 45px;
    height: 100%;
    position: absolute;
    top: 0;
    left: 30px;
    transform: skewX(-45deg);
}

.cm-btn {
    margin: 0 auto;
    width: 285px;
    height: 60px;
    border: 2px solid #fab30d;
    background-color: #fab30d;
    border-radius: 10px;
    display: block;
    overflow:hidden;
    cursor: pointer;
    color: white;
    box-shadow: 0.3em 0.3em 1em rgba(0,0,0,0.3);
}

.cm-btn-small {
    width: 250px;
    height: 50px;
    border: 2px solid #fab30d;
    margin: 0 auto;
}

.cm-btn-text {
    width: 285px;
    height: 60px;
    position: absolute;
    top: 0;
    text-align: center;
    font-size: 20px;
    padding-top: 13px;
    color: white;
}

.cm-btn-text-small {
    width: 250px;
    height: 50px;
    padding-top: 10px;
}

.cm-btn:hover {
    background-color: rgba(4, 3, 0, 0.68);
    transition: background-color 0.3s ease-in-out, color 0.2s ease-in-out, border-color 0.2s ease-in-out, box-shadow 0.2s ease-in-out;
}

.cm-btn-animation{
    display: table-cell;
    height: 60px;
    transform: translateX(-100px);
    animation: shiftByX 5s ease-in-out infinite;
}

@keyframes shiftByX {
    30% {
        transform: translateX(-300px);
    }
    100% {
        transform: translateX(400px);
    }
}



/*Для большого экрана*/
@media only screen and (min-width: 951px) {

    .pr-background-fon {
        background: url("https://interiormydreams.ru/static/fon/fon.jfif");
    }

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 4 + 10px);
        height: calc(100vw * 0.93 / 4 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 4 * 2 + 30px);
        height: calc(100vw * 0.93 / 4 + 100px);
        float: left
    }

    .plan-img {
        width: calc(67vw);
        display: inline-block;
        text-align: center;
        vertical-align: top;
    }

    .plan-img img {
        vertical-align: baseline;
        height: 77vh;
    }

    .project-description {
        display: inline-block;
        margin-left: 10px;
        padding: 0 5px;
        width: calc(30vw);
        vertical-align: top;
        text-overflow: ellipsis;

    }

    .description-height {
        /*transition: height 1s ease;*/
    }

    .show-all {
        width: 100%;
    }

    .show-all .description-height {
        height: 100%;
    }

    .show-small {
        width: 100%;
    }

    .show-small .description-height {
        height: 60vh;
    }

    .description-gradient {
        width: 100%;
        height: 10vh;
    }

    .description-btn {
        width: calc(30vw);
        background-image: url("https://interiormydreams.ru/static/fon/fon.jfif");
        -webkit-mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 65%, transparent);
                mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 65%, transparent);
        margin-left: -5px;
        height: 17vh;
    }

    .auto-size {
        font-size: 2vmin !important;
        text-align: justify;
    }

    .description-text{
        color: white !important;
    }

    .mobile-description{
        display: none;
    }

    .no-desctop {
        display: none;
    }


}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .mobile-description {
        border-bottom: 1px solid rgb(245, 162, 9);
        border-top: 1px solid rgb(245, 162, 9);
    }

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 2 + 10px);
        height: calc(100vw * 0.93 / 2 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 2 * 2 + 10px);
        height: calc(100vw * 0.93 / 2 + 100px);
        float: left
    }

    .plan-img {
        height: 10vh;
        display: inline-block;
        text-align: center;
    }

    .plan-img img {
        vertical-align: baseline;
        width: calc(100vw);
    }

    .auto-size {
        font-size: 2vmin;
        text-align: justify;
    }


    .project-description {
        width: calc(100vw);
        display: inline-block;
        vertical-align: top;
        height: 100%;
        /*transition: height 1s ease;*/
    }

    .mobile-description{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: bold;
        grid-column-gap: 20px;
        column-gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        border-top: 1px solid rgba(0,0,0,.1);
        text-transform: uppercase;
        padding: 10px 15px;
    }

    .description-text{
        color: black !important;
    }

    .show-small .description-height {
        display: none;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 + 10px);
        height: calc(100vw * 0.93 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 + 10px);
        height: calc(100vw * 0.93 + 100px);
        float: left
    }

    .plan-img {
        height: 10vh;
        display: inline-block;
        text-align: center;
    }

    .plan-img img {
        vertical-align: baseline;
        width: calc(100vw);
    }

    .auto-size {
        font-size: 2.5vmin !important;
        text-align: justify;
    }


    .project-description {
        width: calc(100vw);
        display: inline-block;
        vertical-align: top;
        height: 100%;
        /*transition: height 1s ease;*/
    }

    .show-all {
        width: 100%;
    }

    .show-all .description-height {
        height: 100%;
    }

    .show-small {
        width: 100%;
    }

    .show-small .description-height {
        display: none;
    }

    .mobile-description{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: bold;
        grid-column-gap: 20px;
        column-gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        border-top: 1px solid rgba(0,0,0,.1);
        text-transform: uppercase;
        padding: 10px 15px;
    }

    .description-text{
        color: black !important;
    }

    .mobile-description {
        border-bottom: 1px solid rgb(245, 162, 9);
        border-top: 1px solid rgb(245, 162, 9);
    }
}

.cell-div-10 {
    display: table-cell;
    vertical-align: middle;
    text-align: end;
}

.pj-cell-div-right {
    display: table-cell !important;
    vertical-align: middle;
    text-align: right;
    width: 10%;
    padding: 5px 0;
}

.cell-div-100 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.sun-editor-editable {
    background-color: transparent !important;
}

#select-in-image img {
    display: none;
}

.sub-menu a:hover, .sub-menu .active {
    color: rgb(245, 162, 9);
    -webkit-text-decoration: underline rgb(245, 162, 9);
    text-decoration: underline rgb(245, 162, 9);
}

#select-in-image .multiSelectContainer ul {
    max-height: 170px !important;
}
#select-in-image .multiSelectContainer li {
   padding: 0px !important;
}

.first-block {
    overflow: hidden;
    height: calc(100vh - 50px);
}

@keyframes leaves {
    0%, 75%{
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1.0);
        opacity: 1;
    }
}

.mp-btn{
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 1s ease-in-out alternate;
    -webkit-animation: leaves 1s ease-in-out alternate;
}

.mp-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.mp-inline {
    display: inline-block;
}


@keyframes shiftByY {

    100% {
        transform: translateY(7px);
    }
}

.mp-tariff-block {
    background-color: #2a2a2a;
    color: white;
    text-align: center;
    vertical-align: top;
    min-height: calc(100vh);
}



.mp-lc {
    text-align: center;
    padding-top: 25px;
}


.mp-title {
    position: relative;
    animation: leaves 2s ease-in-out alternate;
    -webkit-animation: leaves 2s ease-in-out alternate;
    padding: 40px;
    text-align: center;
}

.mp-info {
    position: relative;
    font-size: 20px;
    animation: leaves 2.5s ease-in-out alternate;
    -webkit-animation: leaves 2.5s ease-in-out alternate;
}

.fb-bg-img {
    background-attachment: scroll;
    resize: both;
}

.mp-button-block {
    width: 100%;
    position: absolute;
    /*background-image: -webkit-linear-gradient(top, rgba(42, 42, 42, 0.8), rgba(42, 42, 42, 1));*/
    margin-top: 30px;
    align-content: center;
    text-align: center;
}

.mp-work-block {
    text-align: center;
    vertical-align: top;
}

.video {
    width: calc(2.5 * (114vh - 70px));
    height: calc(114vh - 70px);
    margin-left: calc(-1 * (2.5 * (114vh - 70px) - 100vw) / 2);
    margin-top: -14vh;
}

.mp-background {
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.8) 85%, rgba(42, 42, 42, 1));
    position: absolute;
    top: 70px;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.mp-video-block {
    overflow: hidden;
    height: calc(100vh - 70px);
}

.mp-arrow-next {
    width: 100%;
    position: relative;
    top: 450px;
    margin-top: 30px;
    align-content: center;
    text-align: center;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .mp-title {
        font-size: 80px;
        top: 15vh
    }

    .mp-info {
        top: 10vh;
        font-size: 24px;
    }

    .fb-bg-img {
        background-position: bottom -50px left 0;
        background-size: 100vw
    }

    .mp-btn {
        top: calc(25vh);
    }

    .mp-arrow-next {
        top: -30vh;
    }

    .mp-bg-img-size {
        height: 710px;
    }

    .mp-work-block {
        width: 260px;
        padding: 2px 40px 5px 40px;
    }

    .mp-button-block {
        top: calc(81vh - 2px)
    }
    .portfolio-id-link {
        position: relative;
        top: -13vh
    }

}

/*Для маленького экрана, телефон*/
@media only screen and (max-width: 1169px) and (min-width: 400px) {
    .mp-title {
        font-size: 40px;
        top: 10%;
    }

    .mp-info {
        top: 10vh;
        padding: 0 5px;
    }

    .mp-btn {
        top: 30%;
    }

    .mp-block .mp-btn {
        top: 25vh
    }

    .mp-arrow-next {
        top: -25vh;
    }

    .mp-button-block {
        top: 56vh;
        display: none;
    }

    .fb-bg-img {
        background-position: center bottom 100px;
        background-size: cover;
    }

    .mp-bg-img-size {
        height: 100vh;
    }

    .mp-work-block {
        height: calc(22vh / 2 - 20px);
        width: calc(50vw - 20px);
        font-style: italic;
        font-size: 15px;
        margin: 10px;
        border-width: 3px;
        border-style: solid;
        padding: 5px 5px;
        border-image: linear-gradient(
                to bottom,
                white,
                rgba(0, 0, 0, 0)
        ) 1 49%;
        /*border-right: 0;*/
        /*border-bottom: 0;*/
    }

    .mp-work-block img {
        display: none;
    }

    .mp-button-block {

        margin-top: 20vh;

    }
    .portfolio-id-link {
        position: relative;
        top: -10vh
    }

    .mp-bt-clt {
        top: 42% !important;
    }
}
/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 399px) {
    .mp-title {
        font-size: 40px;
        top: 10%;
    }

    .mp-info {
        top: 10%;
        padding: 0 5px;
    }

    .mp-btn {
        top: 30%;
    }

    .mp-bt-clt {
        top: 42% !important;
    }

    .mp-block .mp-btn {
        top: 20vh
    }

    .mp-arrow-next {
        top: -25%;
    }

    .mp-button-block {
        top: 56vh;
        display: none;
    }

    .fb-bg-img {
        background-position: center bottom 100px;
        background-size: cover;
    }

    .mp-bg-img-size {
        height: 100vh;
    }

    .mp-work-block {
        height: calc(22vh / 2 - 20px);
        width: calc(50vw - 20px);
        font-style: italic;
        font-size: 13px;
        margin: 10px;
        border-width: 3px;
        border-style: solid;
        padding: 5px 5px;
        border-image: linear-gradient(
                to bottom,
                white,
                rgba(0, 0, 0, 0)
        ) 1 49%;
        /*border-right: 0;*/
        /*border-bottom: 0;*/
    }

    .mp-work-block img {
        display: none;
    }

    .mp-button-block {

        margin-top: 20vh;

    }
    .portfolio-id-link {
        position: relative;
        top: -10vh
    }
}



.pb-mp-btn {
    top: 10%;
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 3s ease-in-out alternate;
    -webkit-animation: leaves 3s ease-in-out alternate;
}

.animation-on-view {
    animation: leaves 0.5s ease-out alternate;
    -webkit-animation: leaves 0.5s ease-out alternate;
}

.left-top-border-radius {
    border-radius: 20px 0 0 0;
}
.right-top-border-radius {
    border-radius: 0 20px 0 0 ;
}
.left-bt-border-radius {
    border-radius:  0 0 0 20px ;
}
.right-bt-border-radius {
    border-radius: 0 0 20px 0 ;
}

.mp-portfolio {
    /*padding-top: 25px;*/
}

.mp-pb-img {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    justify-content: center;
}

.anim-from-left-to-right {
    animation-name: fromLTR;
    animation-duration: 1s;
}

.anim-from-right-to-left  {
    animation-name: fromRTL;
    animation-duration: 1s;

}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .portfolio-g {
        width: 45vw;
    }

    .mp-pb-img img {
        height: 76vh;
    }

    .portfolio-v {
        width: 25vw;
    }

    .first-img{
        left: calc(30vw /2 )
    }
    .second-img{
        left: calc(30vw /2 + 10px);
    }

    .left-bt-border-radius-not-phone {
        border-radius:  0 0 0 20px ;
    }
    .right-bt-border-radius-not-phone {
        border-radius: 0 0 20px 0 ;
    }

    @keyframes fromLTR {
        from {left: -1000px;
        }
        to {left: calc(30vw /2 )}
    }

    @keyframes fromRTL {
        from {left: 1000px}
        to {left: calc(30vw /2 + 10px)}
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .portfolio-g {
        width: calc(60vw - 10px);
    }

    .mp-pb-img img {
        height: calc(40vw)
    }

    .portfolio-v {
        width: calc(30vw - 10px);
    }

    .first-img{
        left: calc(15vw /2 )
    }
    .second-img{
        left: calc(15vw /2 + 10px);
    }

    .mp-portfolio {
        min-height: calc(100vh - 75px);
    }

    .left-bt-border-radius-not-phone {
        border-radius:  0 0 0 0;
    }
    .right-bt-border-radius-not-phone {
        border-radius: 0 0 0 0;
    }

    @keyframes fromLTR {
        from {left: -100px;
        }
        to {left: calc(15vw /2 )}
    }

    @keyframes fromRTL {
        from {left: 100px}
        to {left: calc(15vw /2 + 10px)}
    }
}
.tariff-title{
    padding: 40px;
}


/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .tariff-title {
        /*font-size: 80px;*/
    }

    .mp-tb-tariff-constructor {
        width: 700px !important;
    }

    .mp-tb-tariff {
        background-color: white;
        color: #2a2a2a;
        width: 290px;
        height: 430px;
        padding: 5px;
        margin: 0 15px;
        text-align: center;
        border-radius: 15px;
        position: relative;
    }

    .mp-tariff-title{
        font-size: 35px;
        padding: 10px;
        text-transform: uppercase;
        color: #fab30d;
    }

    .mp-tariff-info{
        font-size: 16px; padding: 2px 15px;
        text-align: center;
        height: 110px
    }
    .mp-tariff-info2{
        font-size: 16px;
        padding: 2px 15px;
        height: 105px
    }

    .tariff-test-img img {
        width: 195px;
        margin-bottom: -45px;
    }

    @keyframes fromLTR2 {
        from {left: -1000px;
        }
        to {left: 0}
    }

    @keyframes fromRTL2 {
        from {left: 1000px}
        to {left: 0}
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .mp-tb-tariff-constructor {
        width: 260px;
    }

    .mp-tariff-title{
        font-size: 35px;
        padding: 10px;
        text-transform: uppercase;
        color: #fab30d;
        text-align: center;
    }

    .mp-tb-tariff {
        width: calc(100vw - 35px);
        background-color: white;
        color: #2a2a2a;
        padding: 5px;
        margin-left: 10px;
        margin-bottom: 10px;
        text-align: center;
        border-radius: 15px;
    }

    .mp-t-hide{
        display: none;
    }

    .prev-checkbox {
        width: 100% !important;
        padding-left: 10vw !important;
    }

    .prev-checkbox label {
        font-size: 19px !important;
    }

    .mp-tariff-info{
        font-size: 16px;
        padding: 2px 15px;
        text-align: justify;
    }

    .mp-tariff-info2{
        font-size: 16px;
        padding: 2px 15px;
        height: 105px;
        text-align: justify;
    }

    .mp-tariff-title{
        font-size: 20px;
        padding: 5px;
        width: 50%;
        display: inline-block;
        /*background-color: white;*/
        /*border-radius: 5px;*/
    }

    .tariff-test-img img {
        width: 150px;
        margin-bottom: 10px;
    }

}

.prev-checkbox {
    width: 42%;
    margin: 0 auto;
    text-align: left;
}
.prev-checkbox .clc-checkbox {
    cursor: auto;
    font-size: 22px;
}

.tariff-id-link {
    position: relative;
    top: -10vh
}


.mp-lc {
    text-align: center;
    padding-top: 25px;
}

.lc-open-btn {
    transform: rotate(-90deg);
    width: 5%
}
.lc-close-btn {
    width: 5%
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {

    .lc-phone{
        display: none;
    }

    .lc-image-border{
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 85px;
        float: right;
        background-color: white;
        position: relative;
        left: 41px;
    }

    .lc-first-block {
        width: 30%;
        vertical-align: top
    }

    .life-cycle {
        width: 70%;
        text-align: left;
        padding-left: 20px;
        position: relative;
    }

    .lc-description {
        width: 70%
    }

    .lc-mobile-price {
        display: block;
    }
}

@media only screen and (max-device-width: 1168px) {
    .mp-lc {
        padding-bottom: 50px;
    }

    .lc-hide{
        display: none;
    }

    .lc-image-border{
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 46px;
        float: right;
        background-color: white;
    }

    .lc-first-block {
        width:10%;
        padding-bottom: 30px;
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
    }

    .life-cycle {
        width: 90vw;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        display: table-cell;
    }

    .lc-description {
        width: 100%;
        text-align: justify;
    }

    .lc-mobile-price {
        display: none;
    }

    .lc-mobile-price-show {
        padding: 10px;
        animation: showDescLC 0.3s ease-in-out alternate;
        -webkit-animation: showDescLC 0.3s ease-in-out alternate;
    }

    @keyframes showDescLC {
        0%{
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

}
.calculator-top {
    margin-top: 0px;
    text-align: center;
    vertical-align: top;
    padding: 30px;
}

.calculator-title {
    position: relative;
    animation: leaves 2s ease-in-out alternate;
    -webkit-animation: leaves 2s ease-in-out alternate;
    padding-top: 10px;
    text-align: center;
}

.calculate-tariff-result {
    width: 90%;
    margin: 0 auto;
    padding: 30px;
    border-radius: 10px;
    border: 1px solid black;
    background-color: #2a2a2a;
    margin-bottom: 150px;
    overflow: hidden;
    box-shadow: 15px 15px 12px #00000094
}
.calculate-tariff-result div div .tariff-description {
    padding-left: 35px;
}


.calculator-price {
    color: #fab30d;
    font-size: 40px;
    text-align: center;
    width: 100%;
}

.row {
    display: table-row;
}

.modal-br {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 500;
}


.close-modal:hover svg {
    animation-name: scaleBtn;
    animation-duration: 0.2s;
    transform: scale(1.2);
}

@keyframes scaleBtn {
    from {
        transform: scale(1);
    }
    to {
        transform: scale(1.2);
    }
}

.calculator-modal {
    position: fixed;
    top: 15%;
    left: 25%;
    width: 50%;
    border-radius: 10px;
    border: 1px solid #fab30d;
    background-color: #2a2a2a;
    z-index: 501;
    box-shadow: 15px 15px 12px #00000094;
    padding: 20px;
    color: white;
    font-size: 20px;
    animation-name: ascleModal;
    animation-duration: 0.4s;
}

@keyframes ascleModal {
    from {
        transform: scale(0.5);
    }
    to {
        transform: scale(1);
    }
}

.close-calculator {
    position: relative;
    top: -75px;
    left: 99%;
    cursor: pointer;
}

.black {
    color: white;

}

.calculator-next-step {
    width: 20%;
    display: table-cell;
    vertical-align: middle;
    margin: 0 auto;
    padding-right: 10px;
}

.calculator-cell > .mp-btn {
    top: 0
}

.calculator-confirm-btn > .mp-btn {
    top: 0;
    margin-top: 20px;
}


.calculator-cell {
    vertical-align: middle;
    display: table-cell;
}

.calculator-cell a {
    color: white;
}

.calculator-cell a:hover {
    color: #fab30d;
    text-decoration: none;
}

.calculator-prev {
    height: 15vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-current {
    height: 30vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-current-big {
    height: 40vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

.calculator-next {
    height: 15vh;
    display: table-row;
    vertical-align: middle;
    margin: 0 auto;
    text-align: center;
}

/*чекбокс*/
.clc-checkbox {
    display: block;
    position: relative;
    padding-left: 35px;

    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    user-select: none;
    margin: 0 auto;
}

/* Hide the browser's default checkbox */
.clc-checkbox input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
}

/* Create a custom checkbox */
.checkmark {
    position: absolute;
    top: 5px;
    left: 0;
    height: 25px;
    width: 25px;
    /*background-color: white;*/
    border: 1px solid black;
    border-radius: 7px;
}

.white-border > .checkmark {
    border: 1px solid white;

}

.orange-border > .checkmark {
    border: 1px solid #fab30d;
}

/* On mouse-over, add a grey background color */
.clc-checkbox:hover input ~ .checkmark {
    /*background-color: #ccc;*/
}

/* When the checkbox is checked, add a blue background */
.clc-checkbox input:checked ~ .checkmark {
    /*background-color: #2196F3;*/
}

/* Create the checkmark/indicator (hidden when not checked) */
.checkmark:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.clc-checkbox input:checked ~ .checkmark:after {
    display: block;
}

/* Style the checkmark/indicator */
.clc-checkbox .checkmark:after {
    left: 11px;
    top: -10px;
    width: 10px;
    height: 30px;
    border: solid #fab30d;
    border-width: 0 4px 4px 0;
    transform: rotate(45deg);
}

.groupName {
    color: #fab30d;
    text-align: center;

    font-weight: bolder;
    font-size: 20px;

    display: inline-block;
    padding-left: 23%;

    padding-top: 40px;


}

.display-none{
    display: none;
}

.group-content {
    color: #fab30d;
    text-align: center;
    width: 40%;

    font-weight: bolder;
    font-size: 20px;

    display: inline-block;

}

.tariff-description {
    padding-left: 35px;
    font-size: 22px;
}


.tooltip {
    border: 1px solid white;
    background-color: white;
    border-radius: 50%;
    width: 5px;
    height: 5px;
    text-align: center;
    margin: 0 auto;
}


/*left position*/
.tooltipleft {
    position: relative;
    display: inline-block;
    border: 1px solid black;
    background-color: black;
    border-radius: 50%;
    width: 13px;
    height: 13px;
    text-align: center;
    vertical-align: bottom;
    margin-left: 10px;
    margin-bottom: 9px;
    color: white;

}

.tooltipleft .toolttextleft {
    visibility: hidden;
    width: 200px;
    background-color: rgba(105, 105, 105, 1);
    color: #fefefe;
    border: 1px solid black;
    box-shadow: 3px 1px 5px rgba(105, 105, 105, 0.36);
    text-align: center;
    border-radius: 4px;
    padding: 5px;
    font-size: 13px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: 130%;
    top: -100%;

}

.tooltipleft:hover .toolttextleft {
    visibility: visible;
}

.info-icon {
    visibility: hidden;
    position: relative;
    padding-left: 5px;
    cursor: pointer;
}

.tariff-description:hover .info-icon {
    visibility: visible;
}

.info-icon .toolttextleft {
    visibility: hidden;
    width: 200px;
    background-color: rgba(14, 14, 14, 0.9);
    color: #fefefe;
    border: 1px solid #fab30d;
    text-align: center;
    padding: 5px;
    font-size: 13px;
    line-height: 13px;
    /* Position the tooltip */
    position: absolute;
    z-index: 1;
    left: -300%;
    top: -200%;

}

.info-icon:hover .toolttextleft {
    visibility: visible;
}

.group-list {
    width: 30%;
    height: 300px;
    display: inline-block;
    vertical-align: top;
    padding: 10px;
    margin-right: 10px;

}

.group-setting {
    width: 60%;
    display: inline-block;
    padding: 10px;
    margin-left: 10px;
    border-left: 1px solid rgba(0, 0, 0, 0.68);

}

.error {
    color: red;
    font-style: italic;

}

.common-checkbox {
    display: inline-block;
    padding-left: 40px;
    vertical-align: top;
    width: 10%;
    min-width: 10vw;

}

.clc-arrow-next {
    width: 100%;
    align-content: center;
    text-align: center;
}

.calculator-confirm-btn {
    padding-bottom: 30px;
}

.rotate0  img{
    animation-name: rotateCard;
    animation-duration: 0.5s;
}
.rotate1  img{
    animation-name: rotateCard;
    animation-duration: 0.5s;
}

@keyframes rotateCard {
    from {
        transform: perspective(600px) rotateY(-180deg);
    }
    to {
        transform: perspective(600px) rotateY(0deg);
    }
}

.calculator-radio {
    margin: 0 auto;
    text-align: center;
    padding: 10px;
    color: #fab30d;
}

.calculator-radio label {
    padding-right: 40px;
    padding-left: 5px;
    cursor: pointer;
}

input[type=radio] {
    -webkit-appearance: none;
            appearance: none;

    border-radius: 50%;
    width: 16px;
    height: 16px;

    border: 1px solid #fefefe;
    transition: 0.2s all linear;
    margin-right: 5px;

    position: relative;
    top: 4px;
}

.checked input[type=radio]:after {
    content: "";
    position: absolute;
    display: none;
}

/* Show the checkmark when checked */
.checked input[type=radio]:after {
    display: block;
}

/* Style the checkmark/indicator */
.checked input[type=radio]:after {
    left: 8px;
    top: -15px;
    width: 9px;
    height: 26px;
    border: solid #fab30d;
    border-width: 0 3px 3px 0;
    transform: rotate(45deg);
}

.small-height .cm-btn .cm-btn-text {
    line-height: 18px;
}

.request-sended {
    font-size: 34px;
    text-align: center;
    padding: 69px;
    color: #ffffff;
}

.phone-input input {
    color: #ffffff;
    background-color: transparent;
    border: 1px solid #eb9c34;
    border-radius: 10px;
    -moz-border-radius: 10px;
    -webkit-border-radius: 10px;
    margin: 10px 0;
    height: 60px;
    padding: 0 20px;
    font-size: 16px;
    line-height: 1.33;
    width: 100%;
    box-sizing: border-box;
    outline: none;
    -webkit-appearance: none;
}

.test-result {
    width: 60%;
    margin: 0 auto;
}

.test-question {
    text-align: center;
    padding: 10px;
}

.test-answer {
    text-align: center;
    padding: 5px;
    cursor: pointer;
}

.pop-up-full-screen.tariff-popup-description {
    left: 0;
    width: 100%;
}

.support-open-full.icon {
    position: fixed;
    background-color: #fff;
    background-size: 14px;
    top: 50px;
    right: 40vw;
    animation-name: showDescriptionBtn;
    animation-duration: 1.3s;
}

.support-iframe-close.icon, .support-iframe__open.icon {
    position: fixed;
    background-color: #fff;
    background-size: 14px;
    top: 12px;
    right: 40vw;
}


.tariff-popup-description-hide .support-iframe-close.icon {
    right: -10vw;
    animation-name: hideDescriptionBtn;
    animation-duration: 1.2s;
}

.tariff-popup-description-inactive .support-iframe-close.icon,
.tariff-popup-description-inactive .support-open-full.icon,
.tariff-popup-description-hide .support-open-full.icon,
.pop-up-full-screen .support-open-full.icon{
    display: none;
}

.tariff-popup-description .support-iframe-close.icon {
    right: 40vw;
    animation-name: showDescriptionBtn;
    animation-duration: 1.3s;
}

.pop-up-full-screen .support-iframe-close.icon {
    right: 40px !important;
    border: 1px solid black;
}

.icon-close-content {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='14' viewBox='0 0 14 14' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13.207 2.207A1 1 0 0 0 11.793.793L7 5.586 2.207.793A1 1 0 0 0 .793 2.207L5.586 7 .793 11.793a1 1 0 1 0 1.414 1.414L7 8.414l4.793 4.793a1 1 0 0 0 1.414-1.414L8.414 7l4.793-4.793z' fill='%232F3747'/%3E%3C/svg%3E") center center no-repeat
}

.icon-open-new-page {
    background: url("data:image/svg+xml;charset=utf-8,%3Csvg width='14' height='16' viewBox='0 0 14 16' fill='none' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath d='M13 9.503a1 1 0 0 1 1 1v3a1.5 1.5 0 0 1-1.5 1.5h-11a1.5 1.5 0 0 1-1.5-1.5v-11a1.5 1.5 0 0 1 1.5-1.5h3a1 1 0 0 1 0 2H2v10h10v-2.5a1 1 0 0 1 1-1zm-1.7-6.716v-.323l-1.15.314a6.259 6.259 0 0 1-1.65.221c-.6 0-1-.447-1-1a1 1 0 0 1 1-1H13a1 1 0 0 1 1 1v4.504a1 1 0 0 1-1 1c-.552 0-1-.403-1-1a6.3 6.3 0 0 1 .222-1.656l.313-1.147h-.32l-1.078 1.437a10 10 0 0 1-.93 1.072l-3.5 3.498a1 1 0 1 1-1.414-1.414l3.5-3.5a10 10 0 0 1 1.065-.923L11.3 2.787z' fill='%232F3747'/%3E%3C/svg%3E") center center no-repeat
}

.icon {
    position: relative;
    vertical-align: middle;
    display: inline-block;
}

.support-iframe-close {
    top: 12px;
}
.support-open-full {
    top: 50px;
}

.support-iframe-close, .support-open-full {
    border-radius: 50%;
    cursor: pointer;
    width: 32px;
    height: 32px;
    right: calc(100% + 12px);
}

.support-iframe-close:hover, .support-open-full:hover {
    border: 2px solid grey;
}

.support-iframe-close::before, .support-open-full::before {
    content: '';
    display: block;
    position: absolute;
    top: -2px;
    right: -2px;
    bottom: -2px;
    left: -2px;
    border-radius: 50%;
    z-index: -1;
}

.icon:after {
    visibility: hidden;
    content: '\A0';
}


.tariff-popup-description-content{

}

.tariff-popup-description {
    display: block;
    position: fixed;
    left: 60%;
    top: 0;
    width: 40vw;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
    animation-name: showDescription;
    animation-duration: 1s;
}

.tariff-popup-description-hide {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    width: 0;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
    animation-name: hideDescription;
    animation-duration: 1s;
}

.tariff-popup-description-inactive {
    display: block;
    position: fixed;
    left: 100%;
    top: 0;
    width: 0;
    height: 100vh;
    overflow: auto;
    background-color: rgba(255, 255, 255, 0.90);
    padding: 15px;
    z-index: 5001;
}

.pop-up-br {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.7);
    z-index: 5000;
}


@keyframes hideDescriptionBtn {
    0% {
        right: 40vw;

    }
    83% {
        right: 0;
    }
    100% {
        right: -10vw;
    }
}
@keyframes showDescriptionBtn {
    0% {
        right: -10vw;
    }
    99% {
        right: -10vw;
    }

    100% {
        right: 40vw;
    }
}

.pop-up-image{

}

.pop-up-image-full-screen-btn {
    position: absolute;
    right: 30px;
    margin-top: 15px;
    cursor: pointer;
    visibility: hidden;
}

.pop-up-image:hover .pop-up-image-full-screen-btn {
    visibility: visible;
}

.pop-up-image-full-screen-btn:hover svg {
    width: 30px;
    height: 30px;
}
.pop-up-image-full-screen-btn:hover {
   margin-right: -2px;
    margin-top: 12px;
}

.pop-up-image-full-screen {
    position: fixed;
    top: 0;
    left: 0;
    width: 60vw;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    @keyframes showDescription {
        from {
            left: 100%;
        }
        to {
            left: 60vw;
        }
    }

    @keyframes hideDescription {
        from {
            left: 60%;
            width: 40vw;
        }
        to {
            left: 100vw;
            width: 40vw;
        }
    }
    .btn-open-pop-up {
        position: fixed;
        top: 100px;
        right: 10px;
        cursor: pointer;
    }

    .calculator-title {
        font-size: 80px;
    }

    .confirm-options {
        padding: 0 100px;
    }

    .modal-confirm-btn {

        width: 50vw;
        margin: 0 auto;

    }

    .calculate-tariff-step {
        width: 75%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        margin-bottom: 150px;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094
    }

    .calculate-tariff-step-big {
        width: 75%;
        margin: 0 auto;
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        margin-bottom: 150px;
        height: 70vh;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
    }

    .calculator-img {
        display: table-cell;
        width: 35%;
        height: 100%;
        text-align: center;
        margin: 0 auto;
        vertical-align: middle;
        padding: 20px 0 20px 0;
    }

    .calculator-img-border1 {
        border: 3px solid #fab30d;
        border-radius: 50%;
        width: 270px;
        height: 270px;
        margin: 0 auto;
    }

    .calculator-img-border2 {
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 259px;
        height: 259px;
        margin: 3px auto;
    }

    .calculator-img-border2 img {
        width: 250px;
    }

    .calculator-options {
        width: 50%;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        height: 100%;
    }

    .calculator-options-values {
        height: 50vh;
        display: table-cell;
        vertical-align: middle;
    }
    .calculator-options-button {
        height: 10vh;
    }

    .calculator-options .mp-btn {
        top: 0
    }

    .test-options {
        margin: 0 auto;
        height: 40vh;
        width: 75%;
    }

    .test-options2 {
        margin: 0 auto;
        height: 40vh;
        width: 45%;
        padding-left: 20%;
        display: inline-block;
    }

    .calculator-next-step {
        width: 20%;
        display: table-cell;
        vertical-align: middle;
        margin: 0 auto;
        padding-right: 10px;
        border-left: 1px solid #ffffff26;
    }

    .calc-previous {
        display: none;
    }

    .calc-next {
        display: none;
    }

    .desctop {
        display: block;
    }

    .phone {
        display: none;
    }

    .result-selected {
        width: 40%;

    }

    .test-image-block {
        display: inline-flex;
        justify-content: center;
        flex-wrap: nowrap;
        align-items: center;
        flex-direction: column;
        overflow: hidden;
        padding: 5px;
        height: 40vh;
        width: calc(75% / 4) ;
    }

    .test-image-block div img {
        height: 20vw;
        padding: 10px;
        vertical-align: auto;
        cursor: pointer;

    }
    .area .calculator-radio label {
        padding-right: 40px;
    }

    .test-question {
        height: 15vh;
    }

    .result-button-calc{
        display: inline-block !important;
        width: 50%;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {

    .tariff-popup-description {
        width: 100%;
    }

    @keyframes showDescription {
        from {
            left: 100%;
        }
        to {
            left: 0;
        }
    }

    @keyframes hideDescription {
        0% {
            left: 0;
            width: 100%;

        }
        99% {

            width: 100%;
        }
        100% {
            left: 100vw;
            width: 0;
        }
    }

    .support-iframe-close.icon {
        right: 10px !important;
        border: 1px solid black;
    }

    .tariff-popup-description-inactive .support-iframe-close.icon,
    .tariff-popup-description-hide .support-iframe-close.icon{
        display: none;
    }

    .support-open-full.icon{
        display: none;
    }

    .tariff-popup-description {
        left: 0;
        width: 100%;
    }

    .btn-open-pop-up {
        position: fixed;
        top: 90px;
        right: 10px;
        cursor: pointer;
    }

    .mobile-hide{
        display: none;
    }

    .pb-chb-mobile-10 {
        padding-bottom: 7px;
    }

    .tariff-description,
    .clc-checkbox {
        font-size: 16px;
    }

    .calculator-radio {
        text-align: left;
    }

    .calculator-radio label {
        width: 90%;
    }
    .area .calculator-radio label {
        width: 50%;
        padding-right: 0;
    }

    .test-image-block {
        display: inline-block;
        width: 50%;
        padding: 5px;
    }

    .test-image-block img {
        width: 40vw;
        padding: 10px;
        cursor: pointer;
    }

    .desctop {
        display: none;
    }

    .phone {
        display: block;
    }

    .calculator-title {
        padding: 0px;

    }

    .calculator-title h1 {
        font-size: 29px;
    }

    .calculator-top {
        padding: 10px 0 30px 0;
    }

    .calculator-all-step-conteiner {
        position: relative;
        top: -30px;
    }

    .calculator-all-step {
        display: flex;
        justify-content: space-between;
        overflow-x: auto;
        scroll-snap-type: x mandatory;
        overflow-y: hidden;
    }

    .calculate-tariff-step {
        width: 100vw;
        height: 68vh;
        margin: 0 10vw;
        /*!*margin: 0 auto;*!*/
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
        /*display: inline-block;*/
        display: flex;
        justify-content: center;
        flex: 0 0 80vw;
        scroll-snap-align: start;
    }

    .calculate-tariff-step-big {
        width: 100vw;
        margin: 0 10vw;
        /*!*margin: 0 auto;*!*/
        border-radius: 10px;
        border: 1px solid black;
        background-color: #2a2a2a;

        height: 68vh;
        overflow: hidden;
        box-shadow: 15px 15px 12px #00000094;
        /*display: inline-block;*/
        /*scroll-snap-align: start;*/
        display: flex;
        justify-content: center;
        scroll-snap-align: start;

    }

    .calculator-img {
        height: 18vh;
        text-align: center;
        margin: 0 auto;
        vertical-align: middle;
        padding: 20px 0 20px 0;
    }

    .calculator-img-border1 {
        border: 3px solid #fab30d;
        border-radius: 50%;
        width: 13.5vh;
        height: 13.5vh;
        margin: 0 auto;
    }

    .calculator-img-border2 {
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 13vh;
        height: 13vh;
        margin: 0.2vh auto;
    }

    .calculator-img-border2 img {
        width: 12vh;
    }


    .calculator-options {
        vertical-align: middle;
        margin: 0 auto;
        height: 40vh;
        width: 90vw;
        padding: 0 5px;
    }

    .calculate-tariff-result{
        width: 100%;
    }
    .calculate-tariff-result div div .calculator-options {
        height: unset;

    }

    .test-options {
        vertical-align: middle;
        margin: 0 auto;
    }

    .calculator-next-step {
        display: none;
        vertical-align: middle;
        margin: 0 auto;
        padding-right: 10px;
    }

    .calc-previous {
        height: 18vh;
        rotate: 90deg;
        scroll-snap-align: start;
        position: relative;
        top: -16vh;
        left: -40vw;
        display: inline-block;
    }

    .calc-next {
        height: 18vh;
        rotate: 270deg;
        position: relative;
        top: -16vh;
        left: 40vw;
        display: inline-block;
    }

    /*.calc-next div {*/
    /*    position: relative;*/
    /*    top: 89vw;*/
    /*}*/

    /*.calc-previous div {*/
    /*    position: relative;*/
    /*    top: -10px;*/
    /*}*/

    .calculator-confirm-btn {
        position: relative;
        top: -5vh;
    }

    .calculator-modal {
        left: 5%;
        width: 90%;
    }

    .phone-btn {
        display: block;
    }

    .phone-btn div {
        display: block !important;
        padding: 0 !important;
        margin: 10px auto;
        padding-left: 1vw !important;
    }

    .calculator-price {
        font-size: 23px;
        padding-bottom: 10px;
    }


    .tooltipleft .toolttextleft {
        left: -90px;
        top: 120%;
    }

    .request-sended {
        font-size: 34px;
        text-align: center;
        padding: 69px 0;
    }
    .test-result {
        width: 100%;
    }

    .phone-input input  {
        margin: 5px 0;
        height: 45px;
    }

    .test-question {
        font-size: 20px;
    }

    .test-title h1 {
        font-size: 30px;
    }
}



