.mp-lc {
    text-align: center;
    padding-top: 25px;
}

.lc-open-btn {
    transform: rotate(-90deg);
    width: 5%
}
.lc-close-btn {
    width: 5%
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {

    .lc-phone{
        display: none;
    }

    .lc-image-border{
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 85px;
        float: right;
        background-color: white;
        position: relative;
        left: 41px;
    }

    .lc-first-block {
        width: 30%;
        vertical-align: top
    }

    .life-cycle {
        width: 70%;
        text-align: left;
        padding-left: 20px;
        position: relative;
    }

    .lc-description {
        width: 70%
    }

    .lc-mobile-price {
        display: block;
    }
}

@media only screen and (max-device-width: 1168px) {
    .mp-lc {
        padding-bottom: 50px;
    }

    .lc-hide{
        display: none;
    }

    .lc-image-border{
        border: 1px solid #fab30d;
        border-radius: 50%;
        width: 46px;
        float: right;
        background-color: white;
    }

    .lc-first-block {
        width:10%;
        padding-bottom: 30px;
        display: table-cell;
        vertical-align: top;
        padding-left: 10px;
    }

    .life-cycle {
        width: 90vw;
        text-align: left;
        padding-left: 20px;
        padding-right: 20px;
        position: relative;
        display: table-cell;
    }

    .lc-description {
        width: 100%;
        text-align: justify;
    }

    .lc-mobile-price {
        display: none;
    }

    .lc-mobile-price-show {
        padding: 10px;
        animation: showDescLC 0.3s ease-in-out alternate;
        -webkit-animation: showDescLC 0.3s ease-in-out alternate;
    }

    @keyframes showDescLC {
        0%{
            transform: scale(0);
        }
        100% {
            transform: scale(1);
        }
    }

}