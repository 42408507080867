


/*Для большого экрана*/
@media only screen and (min-width: 951px) {

    .pr-background-fon {
        background: url("https://interiormydreams.ru/static/fon/fon.jfif");
    }

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 4 + 10px);
        height: calc(100vw * 0.93 / 4 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 4 * 2 + 30px);
        height: calc(100vw * 0.93 / 4 + 100px);
        float: left
    }

    .plan-img {
        width: calc(67vw);
        display: inline-block;
        text-align: center;
        vertical-align: top;
    }

    .plan-img img {
        vertical-align: baseline;
        height: 77vh;
    }

    .project-description {
        display: inline-block;
        margin-left: 10px;
        padding: 0 5px;
        width: calc(30vw);
        vertical-align: top;
        text-overflow: ellipsis;

    }

    .description-height {
        /*transition: height 1s ease;*/
    }

    .show-all {
        width: 100%;
    }

    .show-all .description-height {
        height: 100%;
    }

    .show-small {
        width: 100%;
    }

    .show-small .description-height {
        height: 60vh;
    }

    .description-gradient {
        width: 100%;
        height: 10vh;
    }

    .description-btn {
        width: calc(30vw);
        background-image: url("https://interiormydreams.ru/static/fon/fon.jfif");
        mask-image: linear-gradient(to top, rgba(0, 0, 0, 1.0) 65%, transparent);
        margin-left: -5px;
        height: 17vh;
    }

    .auto-size {
        font-size: 2vmin !important;
        text-align: justify;
    }

    .description-text{
        color: white !important;
    }

    .mobile-description{
        display: none;
    }

    .no-desctop {
        display: none;
    }


}

/*маленьккое разрешение*/
@media only screen and (max-width: 950px) and (min-width: 661px) {

    .mobile-description {
        border-bottom: 1px solid rgb(245, 162, 9);
        border-top: 1px solid rgb(245, 162, 9);
    }

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 2 + 10px);
        height: calc(100vw * 0.93 / 2 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 / 2 * 2 + 10px);
        height: calc(100vw * 0.93 / 2 + 100px);
        float: left
    }

    .plan-img {
        height: 10vh;
        display: inline-block;
        text-align: center;
    }

    .plan-img img {
        vertical-align: baseline;
        width: calc(100vw);
    }

    .auto-size {
        font-size: 2vmin;
        text-align: justify;
    }


    .project-description {
        width: calc(100vw);
        display: inline-block;
        vertical-align: top;
        height: 100%;
        /*transition: height 1s ease;*/
    }

    .mobile-description{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: bold;
        column-gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        border-top: 1px solid rgba(0,0,0,.1);
        text-transform: uppercase;
        padding: 10px 15px;
    }

    .description-text{
        color: black !important;
    }

    .show-small .description-height {
        display: none;
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 660px) {

    .project-img-shot {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 + 10px);
        height: calc(100vw * 0.93 + 100px);
        float: left
    }

    .project-img-long {
        margin-left: 10px;
        margin-bottom: 10px;
        width: calc(100vw * 0.93 + 10px);
        height: calc(100vw * 0.93 + 100px);
        float: left
    }

    .plan-img {
        height: 10vh;
        display: inline-block;
        text-align: center;
    }

    .plan-img img {
        vertical-align: baseline;
        width: calc(100vw);
    }

    .auto-size {
        font-size: 2.5vmin !important;
        text-align: justify;
    }


    .project-description {
        width: calc(100vw);
        display: inline-block;
        vertical-align: top;
        height: 100%;
        /*transition: height 1s ease;*/
    }

    .show-all {
        width: 100%;
    }

    .show-all .description-height {
        height: 100%;
    }

    .show-small {
        width: 100%;
    }

    .show-small .description-height {
        display: none;
    }

    .mobile-description{
        display: flex;
        align-items: center;
        justify-content: space-between;
        cursor: pointer;
        font-weight: bold;
        column-gap: 20px;
        border-bottom: 1px solid rgba(0,0,0,.1);
        border-top: 1px solid rgba(0,0,0,.1);
        text-transform: uppercase;
        padding: 10px 15px;
    }

    .description-text{
        color: black !important;
    }

    .mobile-description {
        border-bottom: 1px solid rgb(245, 162, 9);
        border-top: 1px solid rgb(245, 162, 9);
    }
}

.cell-div-10 {
    display: table-cell;
    vertical-align: middle;
    text-align: end;
}

.pj-cell-div-right {
    display: table-cell !important;
    vertical-align: middle;
    text-align: right;
    width: 10%;
    padding: 5px 0;
}

.cell-div-100 {
    text-align: center;
    display: table-cell;
    vertical-align: middle;
}

.sun-editor-editable {
    background-color: transparent !important;
}

#select-in-image img {
    display: none;
}

.sub-menu a:hover, .sub-menu .active {
    color: rgb(245, 162, 9);
    -webkit-text-decoration: underline rgb(245, 162, 9);
    text-decoration: underline rgb(245, 162, 9);
}

#select-in-image .multiSelectContainer ul {
    max-height: 170px !important;
}
#select-in-image .multiSelectContainer li {
   padding: 0px !important;
}
