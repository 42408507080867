.first-block {
    overflow: hidden;
    height: calc(100vh - 50px);
}

@keyframes leaves {
    0%, 75%{
        transform: scale(0.7);
        opacity: 0;
    }
    100% {
        transform: scale(1.0);
        opacity: 1;
    }
}

.mp-btn{
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 1s ease-in-out alternate;
    -webkit-animation: leaves 1s ease-in-out alternate;
}

.mp-block {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    display: block;
    margin-left: auto;
    margin-right: auto
}

.mp-inline {
    display: inline-block;
}


@keyframes shiftByY {

    100% {
        -webkit-transform: translateY(7px);
        transform: translateY(7px);
    }
}

.mp-tariff-block {
    background-color: #2a2a2a;
    color: white;
    text-align: center;
    vertical-align: top;
    min-height: calc(100vh);
}



.mp-lc {
    text-align: center;
    padding-top: 25px;
}

