.mobile-header_hidden {
    padding-top: 10px;
}

.mobile-header_row-2 {
    margin-bottom: 20px;
    display: flex;
    align-items: center;
    text-overflow: clip;
}

.mobile-header_whatsapp {
    margin-right: 5px;
    flex-shrink: 0;
}

.header_tel {
    font-size: 18px;
    line-height: 1.3;
    font-weight: 700;
    color: #fff;
    text-decoration: none;
    display: block;
}

.header_tels{
    display: block;
}

.header_tel {
    color: #000;
    font-size: 16px;
    line-height: 1.5;
}

.header_time {
    font-size: 15px;
    color: #fff;
    text-decoration: none;
    display: block;
    text-align: right;
    line-height: 1.3;
    padding-top: 5px;
}

.header_time {
    color: #000;
    font-size: 13px;
    line-height: 1.5;
}

.mobile-header_button {
    margin-left: auto;
    margin-right: 10px;
}

input[type=submit], .main-slider_button, .nav_button, .services_button, .calculator_button, .call-block_button, .portfolio_button {
    height: auto;
    padding-top: 14px;
    padding-bottom: 14px;
    line-height: 1;
}

.nav_button {
    display: block;
    font-size: 13px;
    padding: 7px 10px;
}

.nav_button {
    background-color: #fab30d;
    padding: 10px 24px;
    font-weight: 700;
    text-decoration: none;
    color: #000;
    border-radius: 4px;
    transition: .3s;
}

.header_nav-list li a {
    padding: 0 12px;
    text-transform: uppercase;
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    text-decoration: none;
    position: relative;
    height: 17px;
    display: block;
}

.header_nav-list li a {
    color: #000;
    font-size: 16px;
    padding: 5px 0;
    display: block;
    height: 29px;
}

.mobile-header_hidden ul {
    list-style: none;
}