.pb-mp-btn {
    top: 10%;
    position: relative;
    width: 288px;
    margin: 0 auto;
    animation: leaves 3s ease-in-out alternate;
    -webkit-animation: leaves 3s ease-in-out alternate;
}

.animation-on-view {
    animation: leaves 0.5s ease-out alternate;
    -webkit-animation: leaves 0.5s ease-out alternate;
}

.left-top-border-radius {
    border-radius: 20px 0 0 0;
}
.right-top-border-radius {
    border-radius: 0 20px 0 0 ;
}
.left-bt-border-radius {
    border-radius:  0 0 0 20px ;
}
.right-bt-border-radius {
    border-radius: 0 0 20px 0 ;
}

.mp-portfolio {
    /*padding-top: 25px;*/
}

.mp-pb-img {
    overflow: hidden;
    position: relative;
    display: inline-flex;
    justify-content: center;
}

.anim-from-left-to-right {
    animation-name: fromLTR;
    animation-duration: 1s;
}

.anim-from-right-to-left  {
    animation-name: fromRTL;
    animation-duration: 1s;

}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .portfolio-g {
        width: 45vw;
    }

    .mp-pb-img img {
        height: 76vh;
    }

    .portfolio-v {
        width: 25vw;
    }

    .first-img{
        left: calc(30vw /2 )
    }
    .second-img{
        left: calc(30vw /2 + 10px);
    }

    .left-bt-border-radius-not-phone {
        border-radius:  0 0 0 20px ;
    }
    .right-bt-border-radius-not-phone {
        border-radius: 0 0 20px 0 ;
    }

    @keyframes fromLTR {
        from {left: -1000px;
        }
        to {left: calc(30vw /2 )}
    }

    @keyframes fromRTL {
        from {left: 1000px}
        to {left: calc(30vw /2 + 10px)}
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .portfolio-g {
        width: calc(60vw - 10px);
    }

    .mp-pb-img img {
        height: calc(40vw)
    }

    .portfolio-v {
        width: calc(30vw - 10px);
    }

    .first-img{
        left: calc(15vw /2 )
    }
    .second-img{
        left: calc(15vw /2 + 10px);
    }

    .mp-portfolio {
        min-height: calc(100vh - 75px);
    }

    .left-bt-border-radius-not-phone {
        border-radius:  0 0 0 0;
    }
    .right-bt-border-radius-not-phone {
        border-radius: 0 0 0 0;
    }

    @keyframes fromLTR {
        from {left: -100px;
        }
        to {left: calc(15vw /2 )}
    }

    @keyframes fromRTL {
        from {left: 100px}
        to {left: calc(15vw /2 + 10px)}
    }
}