.tariff-title{
    padding: 40px;
}


/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .tariff-title {
        /*font-size: 80px;*/
    }

    .mp-tb-tariff-constructor {
        width: 700px !important;
    }

    .mp-tb-tariff {
        background-color: white;
        color: #2a2a2a;
        width: 290px;
        height: 430px;
        padding: 5px;
        margin: 0 15px;
        text-align: center;
        border-radius: 15px;
        position: relative;
    }

    .mp-tariff-title{
        font-size: 35px;
        padding: 10px;
        text-transform: uppercase;
        color: #fab30d;
    }

    .mp-tariff-info{
        font-size: 16px; padding: 2px 15px;
        text-align: center;
        height: 110px
    }
    .mp-tariff-info2{
        font-size: 16px;
        padding: 2px 15px;
        height: 105px
    }

    .tariff-test-img img {
        width: 195px;
        margin-bottom: -45px;
    }

    @keyframes fromLTR2 {
        from {left: -1000px;
        }
        to {left: 0}
    }

    @keyframes fromRTL2 {
        from {left: 1000px}
        to {left: 0}
    }
}

/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 1168px) {
    .mp-tb-tariff-constructor {
        width: 260px;
    }

    .mp-tariff-title{
        font-size: 35px;
        padding: 10px;
        text-transform: uppercase;
        color: #fab30d;
        text-align: center;
    }

    .mp-tb-tariff {
        width: calc(100vw - 35px);
        background-color: white;
        color: #2a2a2a;
        padding: 5px;
        margin-left: 10px;
        margin-bottom: 10px;
        text-align: center;
        border-radius: 15px;
    }

    .mp-t-hide{
        display: none;
    }

    .prev-checkbox {
        width: 100% !important;
        padding-left: 10vw !important;
    }

    .prev-checkbox label {
        font-size: 19px !important;
    }

    .mp-tariff-info{
        font-size: 16px;
        padding: 2px 15px;
        text-align: justify;
    }

    .mp-tariff-info2{
        font-size: 16px;
        padding: 2px 15px;
        height: 105px;
        text-align: justify;
    }

    .mp-tariff-title{
        font-size: 20px;
        padding: 5px;
        width: 50%;
        display: inline-block;
        /*background-color: white;*/
        /*border-radius: 5px;*/
    }

    .tariff-test-img img {
        width: 150px;
        margin-bottom: 10px;
    }

}

.prev-checkbox {
    width: 42%;
    margin: 0 auto;
    text-align: left;
}
.prev-checkbox .clc-checkbox {
    cursor: auto;
    font-size: 22px;
}

.tariff-id-link {
    position: relative;
    top: -10vh
}

