.mp-title {
    position: relative;
    animation: leaves 2s ease-in-out alternate;
    -webkit-animation: leaves 2s ease-in-out alternate;
    padding: 40px;
    text-align: center;
}

.mp-info {
    position: relative;
    font-size: 20px;
    animation: leaves 2.5s ease-in-out alternate;
    -webkit-animation: leaves 2.5s ease-in-out alternate;
}

.fb-bg-img {
    background-attachment: scroll;
    resize: both;
}

.mp-button-block {
    width: 100%;
    position: absolute;
    /*background-image: -webkit-linear-gradient(top, rgba(42, 42, 42, 0.8), rgba(42, 42, 42, 1));*/
    margin-top: 30px;
    align-content: center;
    text-align: center;
}

.mp-work-block {
    text-align: center;
    vertical-align: top;
}

.video {
    width: calc(2.5 * (114vh - 70px));
    height: calc(114vh - 70px);
    margin-left: calc(-1 * (2.5 * (114vh - 70px) - 100vw) / 2);
    margin-top: -14vh;
}

.mp-background {
    background-image: -webkit-linear-gradient(top, rgba(0, 0, 0, 0.2), rgba(0, 0, 0, 0.5) 75%, rgba(0, 0, 0, 0.8) 85%, rgba(42, 42, 42, 1));
    position: absolute;
    top: 70px;
    left: 0;
    height: 100vh;
    width: 100%;
    overflow: hidden;
}

.mp-video-block {
    overflow: hidden;
    height: calc(100vh - 70px);
}

.mp-arrow-next {
    width: 100%;
    position: relative;
    top: 450px;
    margin-top: 30px;
    align-content: center;
    text-align: center;
}

/*Для большого экрана*/
@media only screen and (min-width: 1169px) {
    .mp-title {
        font-size: 80px;
        top: 15vh
    }

    .mp-info {
        top: 10vh;
        font-size: 24px;
    }

    .fb-bg-img {
        background-position: bottom -50px left 0;
        background-size: 100vw
    }

    .mp-btn {
        top: calc(25vh);
    }

    .mp-arrow-next {
        top: -30vh;
    }

    .mp-bg-img-size {
        height: 710px;
    }

    .mp-work-block {
        width: 260px;
        padding: 2px 40px 5px 40px;
    }

    .mp-button-block {
        top: calc(81vh - 2px)
    }
    .portfolio-id-link {
        position: relative;
        top: -13vh
    }

}

/*Для маленького экрана, телефон*/
@media only screen and (max-width: 1169px) and (min-width: 400px) {
    .mp-title {
        font-size: 40px;
        top: 10%;
    }

    .mp-info {
        top: 10vh;
        padding: 0 5px;
    }

    .mp-btn {
        top: 30%;
    }

    .mp-block .mp-btn {
        top: 25vh
    }

    .mp-arrow-next {
        top: -25vh;
    }

    .mp-button-block {
        top: 56vh;
        display: none;
    }

    .fb-bg-img {
        background-position: center bottom 100px;
        background-size: cover;
    }

    .mp-bg-img-size {
        height: 100vh;
    }

    .mp-work-block {
        height: calc(22vh / 2 - 20px);
        width: calc(50vw - 20px);
        font-style: italic;
        font-size: 15px;
        margin: 10px;
        border-width: 3px;
        border-style: solid;
        padding: 5px 5px;
        border-image: linear-gradient(
                to bottom,
                white,
                rgba(0, 0, 0, 0)
        ) 1 49%;
        /*border-right: 0;*/
        /*border-bottom: 0;*/
    }

    .mp-work-block img {
        display: none;
    }

    .mp-button-block {

        margin-top: 20vh;

    }
    .portfolio-id-link {
        position: relative;
        top: -10vh
    }

    .mp-bt-clt {
        top: 42% !important;
    }
}
/*Для маленького экрана, телефон*/
@media only screen and (max-device-width: 399px) {
    .mp-title {
        font-size: 40px;
        top: 10%;
    }

    .mp-info {
        top: 10%;
        padding: 0 5px;
    }

    .mp-btn {
        top: 30%;
    }

    .mp-bt-clt {
        top: 42% !important;
    }

    .mp-block .mp-btn {
        top: 20vh
    }

    .mp-arrow-next {
        top: -25%;
    }

    .mp-button-block {
        top: 56vh;
        display: none;
    }

    .fb-bg-img {
        background-position: center bottom 100px;
        background-size: cover;
    }

    .mp-bg-img-size {
        height: 100vh;
    }

    .mp-work-block {
        height: calc(22vh / 2 - 20px);
        width: calc(50vw - 20px);
        font-style: italic;
        font-size: 13px;
        margin: 10px;
        border-width: 3px;
        border-style: solid;
        padding: 5px 5px;
        border-image: linear-gradient(
                to bottom,
                white,
                rgba(0, 0, 0, 0)
        ) 1 49%;
        /*border-right: 0;*/
        /*border-bottom: 0;*/
    }

    .mp-work-block img {
        display: none;
    }

    .mp-button-block {

        margin-top: 20vh;

    }
    .portfolio-id-link {
        position: relative;
        top: -10vh
    }
}


